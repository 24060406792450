<template>
  <div>
    <v-card 
      flat 
      class="pb-6 pb-sm-2 mx-auto secondary rounded-sm"
    >
      <v-card-title> 
        <v-spacer></v-spacer>
        <p 
          v-if="selectedPersonAccount.personType === 'Applicant Tenant' || selectedPersonAccount.personType === 'Tenant'"
          class="my-0 white--text text-h6 text-lg-h5">UK properties to rent
        </p>
        <p v-else class="my-0 white--text text-h6 text-lg-h5">UK properties for sale</p>
        <v-spacer></v-spacer>
      </v-card-title>
      <v-card-subtitle class="text-center">
        <p class="my-0 primary--text text-caption font-weight-bold">Search using 'city', 'postcode' or 'station'</p>
      </v-card-subtitle>
      <v-card-text class="">
        <v-row class="pt-3">
          <v-col class="py-0" cols="12">
            <v-text-field
              v-model="searchText"
              class="rounded-sm"
              label="e.g 'Cardiff', 'CF10', 'CF10 5GP' "
              placeholder="e.g. 'Cardiff', 'CF10', 'CF10 5GX', or 'Cathays station'"
              solo
              dense
              background-color="white"
              @keyup.enter="routeToRightMove"
            ></v-text-field>
          </v-col>
          <v-col cols="12" class="py-0 mt-n3">
            <v-btn
              @click="routeToRightMove"
              class="rounded-sm"
              block 
              color="primary lighten-1"
              large
            >
              <span class="secondary--text text-h6 custom-transform-class text-none">Search</span>
            </v-btn>
            <v-img
              class="mx-auto mt-2"
              alt="RightMove logo"
              src="../../assets/RightMoveLogoDark.svg"
              max-width="80"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'rightMovePrimary-search',
  data() {
    return {
      searchText: ''
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
    }),
  },
  methods: {
    routeToRightMove() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
        let url = `https://www.rightmove.co.uk/property-to-rent/search.html?searchLocation=${this.searchText}&locationIdentifier=&useLocationIdentifier=false&rent=To+rent`
        window.open(url, "_blank")
      }
      else {
        let url = `https://www.rightmove.co.uk/property-for-sale/search.html?searchLocation=${this.searchText}&locationIdentifier=&useLocationIdentifier=false&rent=To+rent`
        window.open(url, "_blank")
      }
    }

  }
}
</script>
