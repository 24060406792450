<template>
  <div>
    <v-card id="viewing">
      <v-card-title class="primary">
        <span v-if="selectedPersonAccount.personType === 'Applicant Tenant' " class="text-h5 white--text">{{ propertyDetails.TenancyProperty.AddressNumber }} {{ propertyDetails.TenancyProperty.Address1 }}</span>
        <span v-if="selectedPersonAccount.personType === 'Applicant Buyer' " class="text-h5 white--text">{{ propertyDetails.AddressNumber }} {{ propertyDetails.Address1 }}</span>
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" color="white" text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-6">
        <v-row class="">
          <v-col class="pb-0" cols="12">
            <v-card flat class="px-0 white"> 
              <v-card-text class="pa-0">
                <v-row class="">
                  <v-col class="mt-n6" cols="12">
                    <ViewingCalendar
                      v-if="availableTimes"
                      :propertyOID="propertyDetails.OID"
                      @appointmentBooked="appointmentBooked" 
                      :availableTimes="availableTimes" 
                    />
                    <div v-else class="text-center">
                      <p class="red--text text-h4">This company does not appear to have any available viewings.</p>
                      <p class="red--text text-6">Please contact the Agency for more details.</p>
                    </div>
                  </v-col>
                  <v-col class="d-flex flex-column mt-n6" cols="12" >
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import ViewingCalendar from '@/components/reusableComponents/ViewingCalendar.vue';
// import { API  } from 'aws-amplify'
import { mapState } from 'vuex'
// import moment from 'moment/src/moment'

export default {
  name: 'prospectBookViewing',
  components: {
    ViewingCalendar
  },
  props: {
    propertyDetails: {
      type: Object
    },
    availableTimes: {
      type: Array,
      required: true
    }
  },
  data() {
    return {
      
    }
  },
  methods: {
    closeDialog() {
      // this.availableTimes = ''
      this.$emit('closeDialog') // emit to SelectedPropertyCard
    },
    appointmentBooked() {
      this.$emit('appointmentBooked') // emit to SelectedPropertyCard
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
    })
  }
}
</script>

<style>

</style>