/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAgentSignPersonAccount = /* GraphQL */ `
  mutation CreateAgentSignPersonAccount(
    $input: CreateAgentSignPersonAccountInput!
    $condition: ModelAgentSignPersonAccountConditionInput
  ) {
    createAgentSignPersonAccount(input: $input, condition: $condition) {
      id
      companyName
      accountCreated
      displayInformation
      personName
      personMobilePhone
      personLandline
      personOID
      companyShortname
      personType
      globalReference
      isArchived
      isAnonymised
      tenancyClosed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAgentSignPersonAccount = /* GraphQL */ `
  mutation UpdateAgentSignPersonAccount(
    $input: UpdateAgentSignPersonAccountInput!
    $condition: ModelAgentSignPersonAccountConditionInput
  ) {
    updateAgentSignPersonAccount(input: $input, condition: $condition) {
      id
      companyName
      accountCreated
      displayInformation
      personName
      personMobilePhone
      personLandline
      personOID
      companyShortname
      personType
      globalReference
      isArchived
      isAnonymised
      tenancyClosed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAgentSignPersonAccount = /* GraphQL */ `
  mutation DeleteAgentSignPersonAccount(
    $input: DeleteAgentSignPersonAccountInput!
    $condition: ModelAgentSignPersonAccountConditionInput
  ) {
    deleteAgentSignPersonAccount(input: $input, condition: $condition) {
      id
      companyName
      accountCreated
      displayInformation
      personName
      personMobilePhone
      personLandline
      personOID
      companyShortname
      personType
      globalReference
      isArchived
      isAnonymised
      tenancyClosed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAgentSignPersonAccountSyncDateTime = /* GraphQL */ `
  mutation CreateAgentSignPersonAccountSyncDateTime(
    $input: CreateAgentSignPersonAccountSyncDateTimeInput!
    $condition: ModelAgentSignPersonAccountSyncDateTimeConditionInput
  ) {
    createAgentSignPersonAccountSyncDateTime(
      input: $input
      condition: $condition
    ) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAgentSignPersonAccountSyncDateTime = /* GraphQL */ `
  mutation UpdateAgentSignPersonAccountSyncDateTime(
    $input: UpdateAgentSignPersonAccountSyncDateTimeInput!
    $condition: ModelAgentSignPersonAccountSyncDateTimeConditionInput
  ) {
    updateAgentSignPersonAccountSyncDateTime(
      input: $input
      condition: $condition
    ) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAgentSignPersonAccountSyncDateTime = /* GraphQL */ `
  mutation DeleteAgentSignPersonAccountSyncDateTime(
    $input: DeleteAgentSignPersonAccountSyncDateTimeInput!
    $condition: ModelAgentSignPersonAccountSyncDateTimeConditionInput
  ) {
    deleteAgentSignPersonAccountSyncDateTime(
      input: $input
      condition: $condition
    ) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAgentSignFavouriteProperty = /* GraphQL */ `
  mutation CreateAgentSignFavouriteProperty(
    $input: CreateAgentSignFavouritePropertyInput!
    $condition: ModelAgentSignFavouritePropertyConditionInput
  ) {
    createAgentSignFavouriteProperty(input: $input, condition: $condition) {
      id
      propertyOID
      personAccountID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAgentSignFavouriteProperty = /* GraphQL */ `
  mutation UpdateAgentSignFavouriteProperty(
    $input: UpdateAgentSignFavouritePropertyInput!
    $condition: ModelAgentSignFavouritePropertyConditionInput
  ) {
    updateAgentSignFavouriteProperty(input: $input, condition: $condition) {
      id
      propertyOID
      personAccountID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAgentSignFavouriteProperty = /* GraphQL */ `
  mutation DeleteAgentSignFavouriteProperty(
    $input: DeleteAgentSignFavouritePropertyInput!
    $condition: ModelAgentSignFavouritePropertyConditionInput
  ) {
    deleteAgentSignFavouriteProperty(input: $input, condition: $condition) {
      id
      propertyOID
      personAccountID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const createAgentSignHiddenPersonAccount = /* GraphQL */ `
  mutation CreateAgentSignHiddenPersonAccount(
    $input: CreateAgentSignHiddenPersonAccountInput!
    $condition: ModelAgentSignHiddenPersonAccountConditionInput
  ) {
    createAgentSignHiddenPersonAccount(input: $input, condition: $condition) {
      id
      personOID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const updateAgentSignHiddenPersonAccount = /* GraphQL */ `
  mutation UpdateAgentSignHiddenPersonAccount(
    $input: UpdateAgentSignHiddenPersonAccountInput!
    $condition: ModelAgentSignHiddenPersonAccountConditionInput
  ) {
    updateAgentSignHiddenPersonAccount(input: $input, condition: $condition) {
      id
      personOID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const deleteAgentSignHiddenPersonAccount = /* GraphQL */ `
  mutation DeleteAgentSignHiddenPersonAccount(
    $input: DeleteAgentSignHiddenPersonAccountInput!
    $condition: ModelAgentSignHiddenPersonAccountConditionInput
  ) {
    deleteAgentSignHiddenPersonAccount(input: $input, condition: $condition) {
      id
      personOID
      createdAt
      updatedAt
      owner
    }
  }
`;
