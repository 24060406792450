/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-west-2",
    "aws_cognito_identity_pool_id": "eu-west-2:6bab0958-df94-4686-966c-60d13575cf2d",
    "aws_cognito_region": "eu-west-2",
    "aws_user_pools_id": "eu-west-2_IXciyDWqJ",
    "aws_user_pools_web_client_id": "3anvs3j92e6k12mkpf12nfsd39",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://ciik432urff5paotabnieyfui4.appsync-api.eu-west-2.amazonaws.com/graphql",
    "aws_appsync_region": "eu-west-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cloud_logic_custom": [
        {
            "name": "agentSignMainRest",
            "endpoint": "https://y72x5vkxj1.execute-api.eu-west-2.amazonaws.com/devel",
            "region": "eu-west-2"
        }
    ]
};


export default awsmobile;
