<template>
  <div class="journey">
    <v-img class="journey-bg" src="../assets/dashboard.jpg"></v-img>

    <v-overlay :value="overlay">
      <v-progress-circular
        indeterminate
        size="64"
      ></v-progress-circular>
    </v-overlay>

    <div 
      class="journey-container pt-2 pb-16"
      v-scroll="onScroll" 
    >
      <div class="mt-3 mb-n6 d-flex">
        <span class="text-caption grey--text text--darken-3">{{ dateString }}</span>
        <v-spacer></v-spacer>
        <span class="text-caption grey--text text--darken-3">Updated: 12 minutes ago</span>
      </div>

      <UserDetails
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="my-details" 
        v-intersect="onDetailsIntersect"
        @completeMyDetails="myDetailsComplete = true" 
      />
      
      <TenantJourneyPropertyCard 
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="propertyDetails" 
        v-intersect="onPropertyIntersect"
      />

      <RightMoveSearch 
        class="px-12 px-sm-12" 
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
      />
      <!-- v-if="myDetailsComplete" -->
      <!-- <ViewingCard 
        class="my-12"
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="viewing"
        v-intersect="onViewingIntersect"
        @viewingsComplete="viewingsComplete = true"
      /> -->
      <OfferCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="offer" 
        v-intersect="onOfferIntersect" 
        @offerComplete="offerComplete = true" 
      />
      <!-- <ReferencingCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="referencing" 
        v-intersect="onReferenceIntersect" 
        @referencingComplete="referencingComplete = true" 
      /> -->
      <!-- <SigningCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="signing" 
        v-intersect="onSigningIntersect" 
        @signingComplete="signingComplete = true" 
      />  -->
      <!-- <MoveInCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="move-in" 
        v-intersect="onMoveInIntersect" 
        @moveInComplete="moveInComplete = true" 
      /> -->
      <RentCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom" 
        id="rent" 
        v-intersect="onRentIntersect" 
      />
      <!-- <RenewalCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom"  
        id="renewal" 
        v-intersect="onRenewalIntersect" 
        @renewalComplete="renewalComplete = true" 
      /> -->
      <!-- <MoveOutCard
        data-aos="zoom-in"
        data-aos-anchor-placement="center-bottom"  
        id="move-out" 
        v-intersect="onMoveOutIntersect" 
      />  -->

      <!-- Drawer ----------------------------------------- -->
      <v-navigation-drawer
        class="d-none d-md-flex flex-column left-navigation-drawer mt-12"
        fixed
        left 
        floating
        width="190"
      >
        <v-list dense>
          <div              
             v-for="item in cards"
            :key="item.name"
          >
            <v-list-item
              v-if="item.show"
              @click="goTo(item.href)"
              :input-value="item.href === currentHref"
              active-class="tertiary darken-1 white--text"
              class="py-1"
            >
              <v-list-item-content>
                <v-list-item-title class="text-center text-body-1 font-weight-regular">{{ item.name }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </div>
        </v-list>
        <div class="copyright-text">
          <p class="text-center agentPrimary--text text--lighten-2 text-caption mt-2">&#169; {{ new Date().getFullYear() }} — <strong>AgentOS</strong></p>
        </div>
      </v-navigation-drawer>

      <v-fab-transition>
        <v-btn
          v-show="showToTopBtn"
          @click="toTop"
          color="agentPrimary"
          fab
          dark
          fixed
          bottom right
          class="mb-16 mb-md-3"
        >
          <v-icon>mdi-chevron-up</v-icon>
        </v-btn>
      </v-fab-transition>

    </div>
  </div>
</template>

<script>
import moment from 'moment/src/moment'
import { API } from 'aws-amplify'
import { mapState } from 'vuex'
import AOS from 'aos'
import 'aos/dist/aos.css'

import UserDetails from '@/components/tenantApp/theJourneyCards/TenantJourneyUserDetails.vue';
// import ViewingCard from '@/components/tenantApp/theJourneyCards/TenantJourneyViewingCard.vue';
import OfferCard from '@/components/tenantApp/theJourneyCards/TenantJourneyOfferCard.vue';
// import ReferencingCard from '@/components/tenantApp/theJourneyCards/TenantJourneyReferencingCard.vue';
// import SigningCard from '@/components/tenantApp/theJourneyCards/TenantJourneySigningCard.vue';
// import MoveInCard from '@/components/tenantApp/theJourneyCards/TenantJourneyMoveInCard.vue';
import RentCard from '@/components/tenantApp/theJourneyCards/TenantJourneyPayingRentCard.vue';
// import RenewalCard from '@/components/tenantApp/theJourneyCards/TenantJourneyRenewalCard.vue';
// import MoveOutCard from '@/components/tenantApp/theJourneyCards/TenantJourneyMoveOutCard.vue';
import RightMoveSearch from '@/components/searches/RightMoveSearch.vue';
import TenantJourneyPropertyCard from '@/components/tenantApp/theJourneyCards/TenantJourneyPropertyCard.vue'

export default {
  name: 'alternative-home-page',
  components: {
    UserDetails,
    TenantJourneyPropertyCard,
    // ViewingCard,
    OfferCard,
    // ReferencingCard,
    // SigningCard,
    // MoveInCard,
    RentCard,
    // RenewalCard,
    // MoveOutCard,
    RightMoveSearch,
  },
  created() {
    this.getPropertyDetails()
    AOS.init()
    this.$notify({ // removes redundant rentDue notifications
      group: 'rentNotifications',
      clean: true
    })
  },
  data() {
    return {
      todaysDate: new Date(),
      overlay: false,
      currentHref: '#offer',

      myDetailsComplete: true,
      viewingsComplete: false,
      offerComplete: false,
      referencingComplete: false,
      signingComplete: false,
      moveInComplete: false,
      renewalComplete: false,
      loading: false,
      cards: [
        { name: 'My details', href: '#my-details', show: true },
        { name: 'Property', href: '#propertyDetails', show: true },
        // { name: 'Viewing', href: '#viewing', show: false },
        { name: 'Offer', href: '#offer', show: true },
        { name: 'Referencing', href: '#referencing', show: false },
        { name: 'Signing', href: '#signing', show: false },
        { name: 'Move in', href: '#move-in', show: false },
        { name: 'Pay rent', href: '#rent', show: true },
        { name: 'Renewal', href: '#renewal', show: false },
        { name: 'Move out', href: '#move-out', show: false },
      ],
      showToTopBtn: false,
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
    }),
    dateString() {
     return moment().format('Do MMMM YYYY')
    }
  },
  methods: {
    onScroll(e) { // Renders the toTop button
      if (typeof window === 'undefined') return
      const top = window.pageYOffset || e.target.scrollTop || 0
      this.showToTopBtn = top > 1000
    },
    toTop() {
      this.$vuetify.goTo(0)
    },
    completeMyDetails() {
      this.loading = true
      setTimeout( () => {
        this.myDetailsComplete = true
        this.loading = false
      },1000)
    },
    getPropertyDetails() {
      API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/tenancies`)
        .then(res => {
          // console.log('Tenant account property', res.data.Data[0])
          this.$store.commit('SET_SELECTED_TENANT_PROPERTY', res.data.Data[0])
        })
    },
    // onIntersect (entries) {
    //   if(entries[0].isIntersecting) {
    //     console.log(entries)
    //   }
    // },
    onDetailsIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#my-details'
      }
    },  
    onPropertyIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#propertyDetails'
      }
    },  
    onViewingIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#viewing'
      }
    },
    onOfferIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#offer'
      }
    },
    onReferenceIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#referencing'
      }
    },
    onSigningIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#signing'
      }
    },
    onMoveInIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#move-in'
      }
    },
    onRentIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#rent'
      }
    },
    onRenewalIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#renewal'
      }
    },
    onMoveOutIntersect(entries) {
      if(entries[0].isIntersecting) {
        this.currentHref = '#move-out'
      }
    },
    goTo(href) {
      this.$vuetify.goTo(href, {
        duration: 300,
        offset: 30,
        easing: 'easeInOutCubic',
      })
    },
  },
}
</script>

<style scoped>
.journey-bg {
  position: fixed;
  top: 0;
  height: 100%;
  opacity: 0.3;
  filter: blur(3px);
}

.left-navigation-drawer {
  /* background: transparent; */
  background: #F5F5F5;
  opacity: 0.8;
  z-index: 1;
}

.journey-container {
  margin: 0 1rem;
}

.copyright-text {
  width: 100%;
  height: 40px;
  position: fixed !important;
  bottom: 50px;
  left: 0;
}

@media only screen and (min-width: 768px) {
  .journey-container {
    width: 80%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 960px) {
  .journey-container {
    width: 70%;
    margin: 0 auto;
  }
}

@media only screen and (min-width: 1264px) {
  .journey-container {
    width: 45%;
    margin: 0 auto;
  }
}

/* .fade-enter-active, .fade-leave-active {
  transition: all 1s ease-out;
}
.fade-enter, .fade-leave-to {
  opacity: 0;
  transform: translateY(-100px);
}

.router-link-active {
  border-left-color: green;
} */

</style>