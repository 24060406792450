<template>
  <v-container grid-list-xs>
    <div class="text-center">
      <v-icon size="100" color="primary" class="mt-10">mdi-check-circle-outline</v-icon>
      <p class="text-h5">Your payment was processed successfully.</p>
      <v-btn
       @click="routeToTenantJourney"
       color="primary"
       depressed
       class="mt-3"
      >
        <v-icon left>mdi-arrow-left</v-icon>
        return to tenancy
      </v-btn>
    </div>
  </v-container>
</template>

<script>
export default {
  name: 'paymentsSuccess',
  methods: {
    routeToTenantJourney() {
      this.$router.push({
        name: `TenantJourney`,
        params: {
          agency: this.$store.state.selectedPersonAccount.companyShortname,
          id: this.$store.state.selectedPersonAccount.personOID
        }
      })
    }
  }
}
</script>

<style>

</style>