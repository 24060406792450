<template>
  <div>
    <v-card>
      <TenancyOfferCard 
        v-if="selectedPersonAccount.personType === 'Applicant Tenant' || selectedPersonAccount.personType === 'Tenant' "
        @cancelOffer="cancelOffer"
      />
      <SalesOfferCard
        v-if="selectedPersonAccount.personType === 'Applicant Buyer' || selectedPersonAccount.personType === 'Buyer' "
        @cancelOffer="cancelOffer"
      />
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import TenancyOfferCard from '@/components/prospects/offerCards/TenancyOfferCard.vue';
import SalesOfferCard from '@/components/prospects/offerCards/SalesOfferCard.vue';

export default {
  name: 'prospectOffer',
  components: {
    TenancyOfferCard,
    SalesOfferCard
  },
  methods: {
    cancelOffer() {
      this.$emit('cancelOffer') // emits to SelectedPropertyCard.vue
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount
    })
  },
}
</script>

<style>

</style>