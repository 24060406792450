<template>
  <div>
    <!-- 
      ==================================================================
      Subtly different from SelectedPropertyCard... this takes a prop... has methods for fav, unfav property etc
      ==================================================================
     -->
    <v-card 
      :loading="mainCardLoading" 
      min-height="405" 
      class="white rounded-sm"
      flat
    >
      <v-card-text class="pt-3 pb-6 px-3">

        <v-row class="top-row">
          <v-col class="left-col pa-0" cols="12">
            <PropertyImageRow
              v-if="propertyPhotos.length" 
              :propertyPhotos="propertyPhotos" 
              :searchResultProperty="searchResultProperty"
              @openCarousel="carouselDialog = true"
            />
            <v-col v-if="!propertyPhotos.length" cols="12" class="pa-0">
              <v-card 
                min-height="200"
                width="100%" 
                flat 
                class="grey rounded-0 d-flex flex-column justify-center"
              >
                <v-card-text class="d-flex flex-column align-center justify-center">
                  <v-icon x-large color="white">mdi-camera-off</v-icon>
                  <p class="white--text">No images available</p>
                </v-card-text>
              </v-card>
            </v-col>
          </v-col>

          <v-col class="right-col d-flex " cols="12">
            <v-row>
              <v-col cols="12" class="pa-0">
                <div class="pa-2 d-flex align-center justify-space-between">
                  <!-- PRICE -->
                  <p class="my-0 d-flex align-center tertiaryDark--text text-body-1 font-weight-light secondary--text">
                    <span v-if="selectedPersonAccount.personType === 'Applicant Tenant'">
                      <span class="font-weight-medium text-h4">{{ propertyDetails.RentAdvertised | filterPriceGlobal  }}</span> 
                      <span class="align-self-center ml-0 mt-1 text--caption grey--text">/pcm</span> 
                      <!-- <span class="font-weight-medium ml-2 text-h4">£12,000,000</span>  -->
                    </span>
                    <span v-if="selectedPersonAccount.personType === 'Applicant Buyer'">
                      <span class="font-weight-medium ml-2 text-h4">{{ propertyDetails.Price | filterPriceGlobal  }}</span> 
                    </span>
                  </p>

                  <!-- <span 
                    v-if="selectedPersonAccount.personType === 'Applicant Tenant' "  
                    class="d-flex align-center white--text text-body-1 font-weight-light secondary--text"
                  >
                    <span class="font-weight-medium text-h4">{{ propertyDetails.RentAdvertised | filterPriceGlobal  }}</span> 
                    <span class="align-self-center ml-1 mt-1 text--caption">pcm</span> 
                  </span>
                  <span 
                    v-if="selectedPersonAccount.personType === 'Applicant Buyer' " 
                    class="d-flex align-center secondary--text text--lighten-2 text-body-1 font-weight-light"
                  >
                    <span class="font-weight-medium ml-2 text-h4">{{ propertyDetails.Price | filterPriceGlobal  }}</span> 
                  </span> -->

                  <v-spacer></v-spacer>

                  <!-- 3 CTA Buttons -->
                  <v-btn 
                    @click="viewPropertyDetails" 
                    class="mr-2"
                    small 
                    outlined
                    color="tertiaryDark" 
                    fab
                  >
                    <v-icon size="30">mdi-information-variant</v-icon>
                  </v-btn>
                  <!-- <v-btn 
                    @click="linkToMaps"
                    fab
                    small
                    outlined 
                    color="tertiaryDark"
                    class="mr-2"
                  >
                    <v-icon small>mdi-google-maps</v-icon>
                  </v-btn> -->
                  <div v-if="!isLinked">
                    <v-btn 
                      v-if="!isFavourite" 
                      @click="addToFavourites" 
                      color="tertiaryDark" 
                      outlined 
                      fab 
                      small
                    >
                      <v-icon>mdi-star-outline</v-icon>
                    </v-btn>
                    <v-btn 
                      v-else 
                      @click="removeFromFavourites" 
                      color="orange" 
                      outlined 
                      fab 
                      small
                    >
                      <v-icon>mdi-star</v-icon>
                    </v-btn>
                  </div>
                  <v-btn 
                    v-if="isLinked"
                    color="orange" 
                    outlined 
                    fab 
                    small
                    disabled
                  >
                    <v-icon>mdi-link-variant</v-icon>
                  </v-btn>
                </div>

                <!-- ADDRESS + TYPE -->
                <div class="pa-2 mt-n1 d-flex">
                  <div>
                    <p class="text-body-1 text-xl-h5 my-0 font-weight-light">
                      <span v-if="selectedPersonAccount.personType === 'Applicant Tenant'">{{ propertyDetails.TenancyProperty.AddressNumber }} {{ propertyDetails.TenancyProperty.Address1 }}</span>
                      <span v-if="selectedPersonAccount.personType === 'Applicant Buyer'">{{ propertyDetails.Address1 }}</span>
                    </p>
                    <p class="text-body-2 text-xl-body-1 font-weight-light my-0">
                      <span v-if="selectedPersonAccount.personType === 'Applicant Tenant' && propertyDetails.TenancyProperty.Address2">{{ propertyDetails.TenancyProperty.Address2 }}</span>
                      <span v-if="selectedPersonAccount.personType === 'Applicant Tenant' && !propertyDetails.TenancyProperty.Address2">{{ propertyDetails.TenancyProperty.Postcode }}</span>
                      <span v-if="selectedPersonAccount.personType === 'Applicant Buyer' && propertyDetails.Address2">{{ propertyDetails.Address2 }}</span>
                      <span v-if="selectedPersonAccount.personType === 'Applicant Buyer' && !propertyDetails.Address2">{{ propertyDetails.Postcode }}</span>
                    </p>
                  </div> 

                  <v-btn 
                    @click="linkToMaps"
                    fab
                    small
                    text 
                    color="tertiaryDark"
                    class="ml-n1 mt-n2"
                  >
                    <v-icon>mdi-google-maps</v-icon>
                  </v-btn>
                </div>

                <!-- <div 
                  v-if="selectedPersonAccount.personType === 'Applicant Tenant'"
                  class="pa-2"
                >
                  <p class="text-body-1 text-xl-h5 my-0 font-weight-medium">{{ propertyDetails.TenancyProperty.AddressNumber }} {{ propertyDetails.TenancyProperty.Address1 }}</p>
                  <p 
                    v-if="propertyDetails.TenancyProperty.Address2" 
                    class="text-body-2 text-xl-body-1 mb-0 my-0">{{ propertyDetails.TenancyProperty.Address2 }}
                  </p>
                  <p v-else class="text-body-2 text-xl-body-1 mb-0 my-0">{{ propertyDetails.TenancyProperty.Postcode }}</p>
                </div>

                <div 
                  v-if="selectedPersonAccount.personType === 'Applicant Buyer'"
                  class="pa-2"
                >
                  <p class="text-body-1 text-xl-h5 my-0 font-weight-medium">{{ propertyDetails.Address1 }}</p>
                  <p 
                    v-if="propertyDetails.Address2" 
                    class="text-body-2 text-xl-body-1 mb-0 my-0">{{ propertyDetails.Address2 }}
                  </p>
                  <p v-else class="text-body-2 text-xl-body-1 mb-0 my-0">{{ propertyDetails.Postcode }}</p>
                </div> -->

                <v-divider class="ma-2"></v-divider>

                <!-- VIEWING DETAILS -->
                <div class="viewing-details-card my-3" v-if="upcomingViewingsDetails.length">
                  <div class="text-center">
                    <p class="tertiaryDark--text text--darken-1 text-h5 text-xl-h4 my-0 font-weight-medium">Viewing {{ upcomingViewingsDetails[0].Start | fromNow }}</p>
                    <p class="text-body-1 my-0"> {{ upcomingViewingsDetails[0].Start | formatDateTime }}</p>
                  </div>
                </div>


                <div 
                  v-if="!upcomingViewingsDetails.length" 
                  class="description mt-1 pa-2"
                >

                  <p class="text-body-1 font-weight-light mb-2">
                    <span v-if="selectedPersonAccount.personType === 'Applicant Tenant'" >{{ propertyDetails.TenancyProperty.PropertyType }} </span>
                    <span v-if="selectedPersonAccount.personType === 'Applicant Buyer'" >{{ propertyDetails.PropertyType }} </span>
                  </p>

                  <div class="d-flex justify-space-between">
                    <div class="d-flex align-center mr-6">
                      <v-icon>mdi-bed</v-icon>
                      <span class="ml-1 text-body-1">
                        <span v-if="selectedPersonAccount.personType === 'Applicant Tenant'">{{ propertyDetails.TenancyProperty.BedroomCount }}</span>
                        <span v-if="selectedPersonAccount.personType === 'Applicant Tenant' && !propertyDetails.TenancyProperty.BedroomCount">n/a</span>
                        <span v-if="selectedPersonAccount.personType === 'Applicant Buyer'">{{ propertyDetails.Bedrooms }}</span>
                        <span v-if="selectedPersonAccount.personType === 'Applicant Buyer' && !propertyDetails.Bedrooms">n/a</span>
                      </span>
                    </div>
                    <div class="d-flex align-center mr-6">
                      <v-icon>mdi-toilet</v-icon>
                      <span class="ml-1 text-body-1">
                        <span v-if="selectedPersonAccount.personType === 'Applicant Tenant'">{{ propertyDetails.TenancyProperty.BathroomCount }}</span>
                        <span v-if="selectedPersonAccount.personType === 'Applicant Tenant' && !propertyDetails.TenancyProperty.BathroomCount">n/a</span>
                        <span v-if="selectedPersonAccount.personType === 'Applicant Buyer'">{{ propertyDetails.Bathrooms }}</span>
                        <span v-if="selectedPersonAccount.personType === 'Applicant Buyer' && !propertyDetails.Bathrooms">n/a</span>
                      </span>
                    </div>
                    <div class="d-flex align-center mr-auto">
                      <v-icon>mdi-floor-plan</v-icon>
                      <span class="ml-1 text-body-1">
                        <span v-if="selectedPersonAccount.personType === 'Applicant Tenant'" class="text-h6 font-weight-medium">{{ propertyDetails.TenancyProperty.FloorArea }}</span>
                        <span v-if="selectedPersonAccount.personType === 'Applicant Tenant' && propertyDetails.TenancyProperty.FloorArea" class="">sq. {{ propertyDetails.TenancyProperty.FloorAreaUnits }}</span>
                        <span v-if="selectedPersonAccount.personType === 'Applicant Tenant' && !propertyDetails.TenancyProperty.FloorArea" class="">n/a</span>
                        <span v-if="selectedPersonAccount.personType === 'Applicant Buyer'" class="text-h6 font-weight-medium">{{ propertyDetails.FloorArea }}</span>
                        <span v-if="selectedPersonAccount.personType === 'Applicant Buyer' && propertyDetails.FloorAreaUnits" class="">sq. {{ propertyDetails.FloorAreaUnits }}</span>
                        <span v-if="selectedPersonAccount.personType === 'Applicant Buyer' && !propertyDetails.FloorAreaUnits" class="">n/a</span>
                      </span>
                    </div>
                  </div>


                  <!-- <div v-if="selectedPersonAccount.personType === 'Applicant Tenant'">
                    <span class="font-weight-light text-body-1">{{ descriptionText(propertyDetails.TenancyProperty.Description) }}</span>
                    <v-btn 
                      v-if="propertyDetails.TenancyProperty.Description.length > 120" 
                      @click="viewPropertyDetails"
                      class="pl-0"
                      color="success" 
                      text 
                      small
                    >
                      <span class="tertiaryDark--text text-body-1 mb-1 custom-transform-class text-none">more</span>
                    </v-btn>
                  </div> -->
                  <!-- <div v-if="selectedPersonAccount.personType === 'Applicant Buyer'" >
                    <span class="font-weight-light text-body-1">{{ descriptionText(propertyDetails.Description) }}</span>
                    <v-btn 
                      v-if="propertyDetails.Description.length > 220" 
                      @click="viewPropertyDetails"
                      class="pl-0"
                      color="success" 
                      text 
                      small
                    >
                      <span class="tertiaryDark--text text-body-1 mb-1 custom-transform-class text-none">more</span>
                    </v-btn>
                  </div> -->
                </div>
              </v-col>
            </v-row>
          </v-col>
        </v-row>

        <v-row class="bottom-row mt-5" align="center">
          <v-col cols="12" class="px-2 py-0 d-flex justify-space-between">
            <v-btn 
              v-if="!upcomingViewingsDetails.length" 
              @click="openViewingsDialog"
              class="rounded-sm"
              color="tertiaryDark" 
              dark
              depressed
              width="32%"
              :loading="loading"
            >
              <span class="custom-transform-class text-none">Viewing</span>
            </v-btn>
            <v-btn 
              v-if="upcomingViewingsDetails.length"
              @click="cancelViewing"
              class="rounded-sm"
              :loading="loading"
              color="red" 
              outlined
              width="32%"
              dark
              depressed 
            >
              <span class="custom-transform-class text-none">Cancel</span>
            </v-btn>
            <v-btn 
              @click="feedbackDialog = true"
              class="rounded-sm"
              color="tertiaryDark"
              outlined 
              width="32%"
              dark
              depressed 
            >
              <span class="custom-transform-class text-none">Feedback</span>
            </v-btn>
            <v-btn 
              @click="makeOffer"
              class="rounded-sm"
              color="tertiaryDark"
              :outlined="!upcomingViewingsDetails.length"
              width="32%" 
              depressed 
              dark
            >
              <span class="custom-transform-class text-none">Offer</span>
            </v-btn>

          </v-col>
        </v-row>
      </v-card-text>
    </v-card>

    <!-- Dialogs ---------------------------------------------- -->
    <!-- ------------------------------------------------------ -->

    <!-- Book a viewing -->
    <v-dialog
      v-model="viewingDialog"
      max-width="1200px"
      :fullscreen="$vuetify.breakpoint.xsOnly"
      transition="dialog-transition"
    >
      <ProspectBookViewing 
        :propertyDetails="propertyDetails"
        :availableTimes="availableTimes"
        @closeDialog="closeDialog"
        @appointmentBooked="appointmentBooked"
      />
    </v-dialog>

    <!-- Feedback -->
    <v-dialog
      v-model="feedbackDialog"
      max-width="700px"
      transition="dialog-transition"
    >
      <ProspectFeedback 
        @closeFeedbackDialog="feedbackDialog = false"
        :propertyDetails="propertyDetails"
      />
    </v-dialog>

    <!-- Make offer -->
    <v-dialog
      v-model="offerDialog"
      persistent
      max-width="700px"
      transition="dialog-transition"
    >
      <ProspectOffer
        @cancelOffer="offerDialog = false"
      />
    </v-dialog>

    <!-- Photo carousel -->
     <v-dialog
      v-model="carouselDialog"
      max-width="700px"
      transition="dialog-transition"
    >
      <v-card class="pa-3">
        <v-carousel hide-delimiters v-if="propertyPhotos.length">
          <v-carousel-item
            v-for="(image) in propertyPhotos"
            :key="image.OID"
            :src="image.WebAddress"
            reverse-transition="fade-transition"
            transition="fade-transition"
          ></v-carousel-item>
        </v-carousel>
        
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import moment from 'moment/src/moment'
import { API, graphqlOperation  } from 'aws-amplify'
import { createAgentSignFavouriteProperty, deleteAgentSignFavouriteProperty } from '../../graphql/mutations'
// import { createFavouriteProperty } from '../../graphql/mutations'

import { mapState } from 'vuex'
import ProspectBookViewing from '@/components/prospects/ProspectBookViewing.vue';
import ProspectFeedback from '@/components/prospects/ProspectFeedback.vue';
import ProspectOffer from '@/components/prospects/ProspectOffer.vue';
import PropertyImageRow from '@/components/property/PropertyImageRow.vue';

export default {
  name: 'mobilePropertySearchCard',
  components: {
    PropertyImageRow,
    ProspectBookViewing,
    ProspectFeedback,
    ProspectOffer,
  },
  props: {
    propertyDetails: { // Main property object 
      type: Object,
      required: false,
    },
    cardTitleColor: {
      type: String,
      default: 'secondary'
    }
  },
  created() {
    this.checkDiaryBookings()
    this.getPropertyPhotos()
    // this.checkPropertyOffers()
  },
  data() {
    return {
      searchResultProperty: true, // a prop used to distinguish the result property card from the main property card
      mainCardLoading: false,
      loading: false,
      // Property
      propertyDetailsDialog: false,
      carouselDialog: false,
      propertyPhotos: '',
      // Viewing
      viewingDetails: '',
      viewingDialog: false,
      upcomingViewingsDetails: '',
      availableTimes: '',
      // Feedback
      feedbackDialog: false,
      feedbackSent: false,
      // Offer
      existingOffers: '',
      offerDialog: false,
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
      favourites: state => state.favouriteProperties,
      linkedProperties : state => state.linkedProperties,
    }),
    isFavourite() {
      if(this.favourites) {
        return this.favourites.some(item => item.propertyOID === this.propertyDetails.OID)
      }
      return false
    },
    isLinked() {
      if(this.linkedProperties) {
        return this.linkedProperties.some(item => item.OID === this.propertyDetails.OID)
      }
      return false
    },
  },
  methods: {
    // Component specific ===========================
    addToFavourites() {
      this.mainCardLoading = 'secondary'
      const property = { 
        propertyOID: this.propertyDetails.OID ,
        personAccountID: this.selectedPersonAccount.personOID  
      }
      API.graphql(graphqlOperation(createAgentSignFavouriteProperty, {input: property}))
        .then( () => {
          this.$emit('updateSavedFavourites')
          this.mainCardLoading = false
          this.$store.commit('SET_SELECTED_PROPERTY', this.propertyDetails)
        })
    },
    removeFromFavourites() {
      this.mainCardLoading = 'secondary'
      const fullFavObject = this.favourites.find( fav => fav.propertyOID === this.propertyDetails.OID)
      const propertyAmplifyID = { id: fullFavObject.id }

      API.graphql(graphqlOperation(deleteAgentSignFavouriteProperty, {input: propertyAmplifyID}))
        .then( () => {
          this.$emit('updateSavedFavourites')
          this.mainCardLoading = false
        })
    },
    // Same as property card ============================
    linkToMaps() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
        let url = `https://www.google.com/maps/place/${this.propertyDetails.TenancyProperty.Latitude},${this.propertyDetails.TenancyProperty.Longitude}`;
        window.open(url, "_blank");
      }
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
        let url = `https://www.google.com/maps/place/${this.propertyDetails.Latitude},${this.propertyDetails.Longitude}`;
        window.open(url, "_blank");
      }
    },
    descriptionText(val) {
      const slicedSytring = val.slice(0, 130)
      if(val.length <= 130) return val
      return slicedSytring + "...";
    },
    getPropertyPhotos() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
        API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/tenancies/${this.propertyDetails.OID}/photos`)
          .then(res => {
            this.propertyPhotos = res.data.Data
          })
      }
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
        API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/sales/${this.propertyDetails.OID}/photos`)
          .then(res => {
            this.propertyPhotos = res.data.Data
          })
      }
    },
    viewPropertyDetails() {
      const photoDetails = { photoDetails: this.propertyPhotos}
      const propertyDetailsWithPhotos = {...this.propertyDetails, ...photoDetails}
      this.$store.commit('SET_PROPERTY_TO_VIEW_FULL_DETAILS', propertyDetailsWithPhotos)
      this.$router.push({ 
        name: 'ApplicantPropertyFullDetails',
        params: {
          agency: this.selectedPersonAccount.companyShortname,
          id: this.propertyDetails.OID
        }
      })

      // let resolvedRoute = this.$router.resolve({ 
      //   name: 'PropertyFullDetails', 
      //   params: {
      //     agency: `${this.selectedPersonAccount.companyShortname}`,
      //     id: `${this.propertyDetails.OID}`
      //   },
      // })
      // window.open(resolvedRoute.href, '_blank')

    }, 
    openViewingsDialog() {
      this.loading = true
      if(!this.availableTimes.length) {
        this.checkAvailableTimes()
      } else {
        this.viewingDialog = true
        this.loading = false
      }
    },
    checkDiaryBookings() {
      if(this.isFavourite) {
        API.get('agentSignMainRest', `/get?query=/${this.clientName}/diary/${this.personOID}/appointments/${this.propertyDetails.OID}`)
          .then(res => {
            const viewingDetails = res.data.Data
            if(viewingDetails.length) {
              this.viewingDetails = viewingDetails
              this.checkForUpcomingViewings()
            } else { 
                this.viewingDetails = []
                this.upcomingViewingsDetails = ''
              }
          })
      }
    },
    makeOffer() {
      this.$store.commit('SET_PROPERTY_MAKE_OFFER', this.propertyDetails)
      this.offerDialog = true
    },
    checkPropertyOffers() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant') {
        API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/tenancies/${this.propertyDetails.OID}/offers`)
          .then(res => {
            this.existingOffers = res.data.Data
          })
      }
      if(this.selectedPersonAccount.personType === 'Applicant Buyer') {
        API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/sales/${this.propertyDetails.OID}/offers`)
          .then(res => {
            this.existingOffers = res.data.Data
          })
      }
    },
    checkForUpcomingViewings() {
      this.viewingDetails.forEach(viewing => {
        const date = new Date()
        const jsonDate = JSON.stringify(date)
        const todaysDate = JSON.parse(jsonDate)
        const upcomingViewings = []

        if(viewing.End > todaysDate) {
          upcomingViewings.push(viewing)
          this.upcomingViewingsDetails = upcomingViewings
        } 

      })
    },
    checkAvailableTimes() {
      const preferredDate = moment().add(1, "days").format("YYYY-MM-DD") // hardcoded tomorrow`s date

      API.get('agentSignMainRest', `/get?query=/${this.clientName}/diary/${this.personOID}/appointments/allocations/0/1000?preferredDate=${preferredDate}`)
        .then(res => {
          // console.log('Available times: ', res)
          if(res.data.Data.length) {
            const returnedArray = res.data.Data
            const availableTimes = returnedArray.map( obj => {
              const formattedStart = moment(obj.Start).format("YYYY-MM-DD hh:mm")
              const formattedEnd = moment(obj.End).format("YYYY-MM-DD hh:mm")
              return {
                Start: formattedStart,
                End: formattedEnd,
                StaffID: obj.StaffID,
                StaffName: obj.StaffName,
              }
            })
            this.availableTimes = availableTimes
            this.viewingDialog = true
            this.loading = false
          } else {
            // inform User no available slots
            console.log('No available time slots!!!')
            this.loading = false
          }
        })
    },
    cancelViewing() {
      this.loading = true
      const appointmentID = this.upcomingViewingsDetails[0].OID

      API.del('agentSignMainRest', `/delete?query=/${this.clientName}/diary/${this.personOID}/appointments/${appointmentID}`)
        .then(res => {
          this.loading = false
          if(res.status === '200') {
            this.upcomingViewingsDetails.pop()
            this.checkDiaryBookings()
            this.$emit('viewingCancelled')
          }
        })
    },
    closeDialog() {
      this.viewingDialog = false
    },
    appointmentBooked() {
      this.closeDialog()
      this.addToFavourites()
      this.$emit('appointmentBooked')
    }
  },
  watch: {
    isFavourite() {
      this.checkDiaryBookings()
    }
  },
  filters: {
    formatDate(val) {
      return moment(val).format('DD/MM/YYYY')
    },
    formatDateTime(val) {
      return moment(val).format('dddd Do MMMM, hh:mm a')
    },
    fromNow(val) {
      return moment(val).fromNow()
    }
  },
}
</script>