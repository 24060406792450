<template>
  <div>
    <!-- App reroutes to this view when user clicks on the I (information) button on a property -->
    <v-container v-if="propertyToViewFullDetails" class="pb-16">
      <v-btn @click="backToMyProperties" color="tertiary" text class="pl-0">
        <v-icon left>mdi-arrow-left</v-icon>
        <span>Back to my properties</span>
      </v-btn>
      <div class="three-images mt-3">
        <!-- Images -->
        <v-row v-if="propertyPhotos">
          <v-col v-if="propertyPhotos.length" cols="12">
            <v-row no-gutters class="image-row">
              <v-col class="d-flex align-center justify-center" cols="12" sm="8">
                <v-img 
                  v-if="propertyPhotos" 
                  :src="propertyPhotos[0].WebAddress"
                >
                  <v-btn v-if="propertyPhotos.length > 1" @click="openCarousel" color="agentPrimary" dark depressed absolute bottom right small>
                    <span class="mr-1">+{{ propertyPhotos.length }}</span>
                    <v-icon small>mdi-camera</v-icon>
                  </v-btn>
                </v-img>
              </v-col>
              <v-col class="pl-1 d-none d-sm-flex flex-column" cols="12" sm="4">
                <v-img
                  v-if="propertyPhotos.length > 1"
                  class="mb-1" :src="propertyPhotos[1].WebAddress"
                >
                </v-img>
                <v-card v-else flat class="d-flex align-center justify-center grey lighten-2 rounded-0 ma-1" height="100%" >
                  <v-icon color="white">mdi-camera-off</v-icon>
                </v-card>

                <v-img
                  v-if="propertyPhotos.length > 2" :src="propertyPhotos[2].WebAddress">
                </v-img>
                <v-card v-else flat class="d-flex align-center justify-center grey lighten-2 rounded-0 ma-1" height="100%" >
                  <v-icon color="white">mdi-camera-off</v-icon>
                </v-card>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <!-- /Images -->

        <v-row class="pb-6">
          <v-col cols="12" md="8">
            <div class="d-flex align-start justify-space-between">
              <div v-if="!isApplicantBuyerAccount">
                <p class="text-h6 text-xl-h4 my-0 font-weight-medium">{{ propertyToViewFullDetails.TenancyProperty.AddressNumber }} {{ propertyToViewFullDetails.TenancyProperty.Address1 }}</p>
                <p class="text-body-1 text-xl-h5 my-0">{{ propertyToViewFullDetails.TenancyProperty.Address2 }}</p>
                <p class="text-body-1 text-xl-h5 my-0">{{ propertyToViewFullDetails.TenancyProperty.Address3 }}</p>
                <p class="text-body-1 text-xl-h5 my-0">{{ propertyToViewFullDetails.TenancyProperty.Address4 }}</p>
                <p class="text-body-1 text-xl-h5 mb-0 my-0">{{ propertyToViewFullDetails.TenancyProperty.Postcode }}</p>
              </div>
              <div v-if="isApplicantBuyerAccount">
                <p class="text-h6 text-xl-h4 my-0 font-weight-medium">{{ propertyToViewFullDetails.AddressNumber }} {{ propertyToViewFullDetails.Address1 }}</p>
                <p class="text-body-1 text-xl-h5 my-0">{{ propertyToViewFullDetails.Address2 }}</p>
                <p class="text-body-1 text-xl-h5 my-0">{{ propertyToViewFullDetails.Address3 }}</p>
                <p class="text-body-1 text-xl-h5 my-0">{{ propertyToViewFullDetails.Address4 }}</p>
                <p class="text-body-1 text-xl-h5 mb-0 my-0">{{ propertyToViewFullDetails.Postcode }}</p>
              </div>
              <v-spacer></v-spacer>
              <v-btn 
                @click="linkToMaps"
                fab
                x-small
                outlined 
                color="tertiary"
              >
                <v-icon small>mdi-google-maps</v-icon>
              </v-btn>
            </div>

            <div class="d-flex align-center flex-wrap">
              <p class="mt-3 font-weight-medium text-h3">
                <span v-if="!isApplicantBuyerAccount">{{ propertyToViewFullDetails.RentAdvertised | filterPriceGlobal }}<span class="text-body-1">pcm</span></span>
                <span v-if="isApplicantBuyerAccount">{{ propertyToViewFullDetails.Price | filterPriceGlobal }}</span>
              </p>
              <v-spacer></v-spacer>
              <div>
                <p class="text-caption my-0 text--grey font-weight-thin">Property type</p>
                <div class="d-flex justify-start font-weight-light">
                  <v-icon small left>mdi-home</v-icon>
                  <span v-if="!isApplicantBuyerAccount" >{{ propertyToViewFullDetails.TenancyProperty.PropertyType }}</span>
                  <span v-if="isApplicantBuyerAccount" >{{ propertyToViewFullDetails.propertyType }}</span>
                </div>
              </div>
              <div class="ml-5">
                <p class="text-caption my-0 text--grey font-weight-thin">Bedrooms</p>
                <div class="d-flex justify-start font-weight-light">
                  <v-icon small left>mdi-bed</v-icon>
                  <span v-if="!isApplicantBuyerAccount">{{ propertyToViewFullDetails.TenancyProperty.BedroomCount }}</span>
                  <span v-if="isApplicantBuyerAccount">{{ propertyToViewFullDetails.Bedrooms }}</span>
                </div>
              </div>
            </div>
            <v-divider></v-divider>

            <p class="mt-5 mb-0 font-weight-medium text-h6">Descritpion</p>
            <div v-if="!isApplicantBuyerAccount">
              <p class="mt-3">{{ propertyToViewFullDetails.TenancyProperty.Description }}</p>
              <v-btn
                @click="redirectToVideo(propertyToViewFullDetails.TenancyProperty.VideoURL)"
                v-if="propertyToViewFullDetails.TenancyProperty.VideoURL"
                color="primary"
                depressed
              >
                Video link
                <v-icon right>mdi-open-in-new</v-icon>
              </v-btn>
            </div>
            <div v-if="isApplicantBuyerAccount">
              <p class="mt-3">{{ propertyToViewFullDetails.Description }}</p>
              <v-btn
                @click="redirectToVideo(propertyToViewFullDetails.VideoURL)"
                v-if="propertyToViewFullDetails.VideoURL"
                color="primary"
                depressed
              >
                Video link
              </v-btn>
            </div>
            <v-divider class="mt-3"></v-divider>

            <div v-if="!isApplicantBuyerAccount">
              <p class="mt-5 mb-0 font-weight-medium text-h6">Letting details</p>
              <div class="my-3">
                <div>
                  Deposit: 
                  <span class="text-h6 font-weight-medium">{{ propertyToViewFullDetails.BondRequired | filterPriceGlobal }}</span>
                </div>
                <div>
                  Available from: 
                  <span class="text-h6 font-weight-medium">{{ propertyToViewFullDetails.TermStart | formatDate }}</span>
                </div>
                <div>
                  Minimum term: 
                  <span class="text-h6 font-weight-medium">{{ propertyToViewFullDetails.TermMinimum }} months</span>
                </div>
                <div>
                  Furnish type: 
                  <span class="text-h6 font-weight-medium">{{ propertyToViewFullDetails.Furnished }}</span>
                </div>
              </div>
              <v-divider></v-divider>
            </div>

            <p class="mt-5 font-weight-medium text-h6">Key features</p>
            <div v-if="!isApplicantBuyerAccount" class="d-flex flex-wrap justify-space-between">
              <div class="d-flex align-center">
                <!-- Bedrooms:  -->
                <v-icon large>mdi-bed</v-icon>
                <span class="ml-2 text-h6 font-weight-regular">{{ propertyToViewFullDetails.TenancyProperty.BedroomCount }}</span>
                <span v-if="! propertyToViewFullDetails.TenancyProperty.BedroomCount" class="ml-2 text-h6 font-weight-regular">n/a</span>
              </div>              
              <div class="d-flex align-center">
                <!-- Bathrooms:  -->
                <v-icon large>mdi-toilet</v-icon>
                <span class="ml-2 text-h6 font-weight-regular">{{ propertyToViewFullDetails.TenancyProperty.BathroomCount }}</span>
                <span v-if="! propertyToViewFullDetails.TenancyProperty.BathroomCount" class="ml-2 text-h6 font-weight-regular">n/a</span>
              </div>              
              <div class="d-flex align-center">
                <!-- Floor space:  -->
                <v-icon large>mdi-floor-plan</v-icon>
                <span class="ml-2 text-h6 font-weight-regular">{{ propertyToViewFullDetails.TenancyProperty.FloorArea }}</span>
                <span v-if="propertyToViewFullDetails.TenancyProperty.FloorArea">sq. {{ propertyToViewFullDetails.TenancyProperty.FloorAreaUnits }}</span>
                <span v-if="!propertyToViewFullDetails.TenancyProperty.FloorArea" class="ml-2 text-h6 font-weight-regular">n/a</span>
              </div>              
              <div class="d-flex align-center">
                <!-- Parking:  -->
                <v-icon large>mdi-car</v-icon>
                <span class="ml-2 text-h6 font-weight-regular">{{ propertyToViewFullDetails.TenancyProperty.ParkingType }}</span>
                <span v-if="!propertyToViewFullDetails.TenancyProperty.ParkingType" class="ml-2 text-h6 font-weight-regular">n/a</span>
              </div>
            </div>

            <div v-if="isApplicantBuyerAccount" class="d-flex flex-wrap justify-space-between">
              <div class="d-flex align-center">
                <!-- Bedrooms:  -->
                <v-icon large>mdi-bed</v-icon>
                <span class="ml-2 text-h6 font-weight-regular">{{ propertyToViewFullDetails.Bedrooms }}</span>
                <span v-if="!propertyToViewFullDetails.Bedrooms" class="ml-2 text-h6 font-weight-regular">n/a</span>
              </div>              
              <div class="d-flex align-center">
                <!-- Bathrooms:  -->
                <v-icon large>mdi-toilet</v-icon>
                <span class="ml-2 text-h6 font-weight-regular">{{ propertyToViewFullDetails.Bathrooms }}</span>
                <span v-if="!propertyToViewFullDetails.Bathrooms" class="ml-2 text-h6 font-weight-regular">n/a</span>
              </div>              
              <div class="d-flex align-center">
                <!-- Floor space:  -->
                <v-icon large>mdi-floor-plan</v-icon>
                <span v-if="propertyToViewFullDetails.FloorArea" class="ml-2 text-h6 font-weight-regular">{{ propertyToViewFullDetails.FloorArea }}</span>
                <span v-if="propertyToViewFullDetails.FloorArea">sq. {{ propertyToViewFullDetails.FloorAreaUnits }}</span>
                <span v-if="!propertyToViewFullDetails.FloorArea" class="ml-2 text-h6 font-weight-regular">n/a</span>
              </div>              
              <div class="d-flex align-center">
                <!-- Parking:  -->
                <v-icon large>mdi-car</v-icon>
                <span class="ml-2 text-h6 font-weight-regular" v-if="propertyToViewFullDetails.ParkingCarport">Parking carport</span>
                <span class="ml-2 text-h6 font-weight-regular" v-if="propertyToViewFullDetails.ParkingGarage">Garage</span>
                <span class="ml-2 text-h6 font-weight-regular" v-if="propertyToViewFullDetails.ParkingDoubleGarage">Double garage</span>
                <span class="ml-2 text-h6 font-weight-regular" v-if="propertyToViewFullDetails.ParkingTripleGarage">Triple garage</span>
                <span class="ml-2 text-h6 font-weight-regular" v-if="propertyToViewFullDetails.ParkingOffRoad">Parking off road</span>
                <span class="ml-2 text-h6 font-weight-regular" v-if="propertyToViewFullDetails.ParkingOnRoad">Parking on road</span>
                <span class="ml-2 text-h6 font-weight-regular" v-if="propertyToViewFullDetails.ParkingAllocated">Allocated parking</span>
                <span class="ml-2 text-h6 font-weight-regular" v-if="propertyToViewFullDetails.ParkingPermit">Parking permit</span>
                <span class="ml-2 text-h6 font-weight-regular" v-if="propertyToViewFullDetails.ParkingSecureGated">Secured gated parking</span>
              </div>
            </div>

            <ul v-if="isApplicantBuyerAccount" class="d-flex justify-space-between flex-wrap">
            </ul>
            <v-divider class="mt-3"></v-divider>


            <p v-if="propertyToViewFullDetails.PortalMedia.length" class="mt-5 font-weight-medium text-h6">Portal media</p>
            <div class="portal-media pa-0 mt-3 d-flex flex-wrap align-center">
              <div 
                v-for="obj in propertyToViewFullDetails.PortalMedia" :key="obj.OID"
                class="pa-0 mr-3 mb-3"
              >
                <!-- <span>{{ obj.Caption }}</span> -->
                <v-btn
                  depressed 
                  width="100%"
                  class="py-5"
                  :color="obj.Filename ? 'secondary' : 'primary'"
                  @click="executePortalMedia(obj)"
                >
                  <span class="text-body-1">{{ obj.Caption }}</span>
                  <v-icon right v-if="obj.Filename">mdi-download</v-icon>
                  <v-icon right v-else>mdi-open-in-new</v-icon>
                </v-btn>
              </div>
            </div>

          </v-col>

          <v-col class="" cols="12" md="4">
            <AgencyDetailsCard class="d-none d-md-block" />
            
            <v-card flat class="mt-5 pa-0 rounded-sm">
              <v-card-text class="pa-2">
                <div class="map-wrapper">
                  <LeafletMap 
                    :geoLocation="geoLocation"
                  />
                </div>
              </v-card-text>
            </v-card>


            <RightMoveSearch class="mt-5" />


          </v-col>
        </v-row>
      </div>

      <!-- <p class="red--text text-h5 text-decoration-underline mt-12">Available data:</p>
      <ul class="">
        <li v-for="(value, name, index) in propertyToViewFullDetails" :key="index">
          {{name}}: {{value}}
        </li>
      </ul> -->

      <!-- Dialogs -->
      <!-- =============================================================================================================== -->
      <v-dialog
        v-model="carouselDialog"
        max-width="700px"
        transition="dialog-transition"
      >
        <v-card class="pa-3">
          <v-carousel hide-delimiters>
            <v-carousel-item
              v-for="(image) in propertyPhotos"
              :key="image.OID"
              :src="image.WebAddress"
              reverse-transition="fade-transition"
              transition="fade-transition"
            ></v-carousel-item>
          </v-carousel>
        </v-card>
      </v-dialog>

      <v-footer 
        fixed 
        class="footer-z-index d-md-none secondary py-3 d-flex justify-space-between"
      >
        <v-btn 
          :href="`tel:${agentContactNumber}`"
          width="48%" 
          class="py-3 rounded-sm" 
          color="primary"
          :large="$vuetify.breakpoint.smAndUp"
        ><span class="secondary--text custom-transform-class text-none">Call agent</span></v-btn>
        <v-btn
          :href="`mailto:${propertyToViewFullDetails.AgencyEmail}?subject=Re: ${propertyEmailSubject}`"
          width="48%" 
          class="py-3 rounded-sm" 
          color="primary"
          :large="$vuetify.breakpoint.smAndUp"
        ><span class="secondary--text custom-transform-class text-none">Email agent</span></v-btn>
      </v-footer>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment/src/moment'
import AgencyDetailsCard from '@/components/agency/AgencyDetailsCard.vue';
import RightMoveSearch from '@/components/searches/RightMoveSearch.vue';
import LeafletMap from '@/components/reusableComponents/LeafletMap.vue';

export default {
  name: 'propertyFullDetails',
  components: {
    AgencyDetailsCard,
    RightMoveSearch,
    LeafletMap
  },
  created() {
    // this.getPropertyPhotos()
  },
  computed: {
    ...mapState({
      propertyToViewFullDetails: state => state.propertyToViewFullDetails,
      propertyPhotos: state => state.propertyToViewFullDetails.photoDetails,
      selectedPersonAccount: state => state.selectedPersonAccount,
      selectedProperty: state => state.selectedProperty,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
    }),
    agentContactNumber() {
      if(!this.isApplicantBuyerAccount) return this.propertyToViewFullDetails.AgencyPhoneNumber
      return this.propertyToViewFullDetails.AgencyNumber
    },
    propertyEmailSubject() {
      if(!this.isApplicantBuyerAccount) {
        return `${this.propertyToViewFullDetails.TenancyProperty.AddressNumber } ${this.propertyToViewFullDetails.TenancyProperty.Address1}, ${this.propertyToViewFullDetails.TenancyProperty.Postcode}`
      }
      if(this.isApplicantBuyerAccount) {
        return `${this.propertyToViewFullDetails.AddressNumber } ${this.propertyToViewFullDetails.Address1}, ${this.propertyToViewFullDetails.Postcode}`
      }
      return ''
    },
    geoLocation() {
      if(!this.isApplicantBuyerAccount) {
        return {
          latitude: this.propertyToViewFullDetails.TenancyProperty.Latitude,
          longitude: this.propertyToViewFullDetails.TenancyProperty.Longitude
        }
      }
      if(this.isApplicantBuyerAccount) {
        return {
          latitude: this.propertyToViewFullDetails.Latitude,
          longitude: this.propertyToViewFullDetails.Longitude
        }
      }
      return {}
    },
    isApplicantBuyerAccount() {
      if(this.selectedPersonAccount.personType === 'Applicant Tenant' || this.selectedPersonAccount.personType === 'Tenant') return false
      return true
    }
  },
  data() {
    return {
      // propertyPhotos: '',
      carouselDialog: false,
    }
  },
  methods: {
    redirectToVideo(url) {
      window.open(url, "_blank")
    },
    openCarousel() {
      this.carouselDialog = true
    },
    backToMyProperties() {
      this.$router.go(-1)
      // window.close()
    },
    // getPropertyPhotos() {
    //   if(!this.isApplicantBuyerAccount) {
    //     API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/tenancies/${this.propertyToViewFullDetails.OID}/photos`)
    //       .then(res => {
    //         this.propertyPhotos = res.data.Data
    //       })
    //   }
    //   if(this.isApplicantBuyerAccount) {
    //     API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/sales/${this.propertyToViewFullDetails.OID}/photos`)
    //       .then(res => {
    //         this.propertyPhotos = res.data.Data
    //       })
    //   }
    // },
    linkToMaps() {
      if(!this.isApplicantBuyerAccount) {
        let url = `https://www.google.com/maps/place/${this.propertyToViewFullDetails.TenancyProperty.Latitude},${this.propertyToViewFullDetails.TenancyProperty.Longitude}`;
        window.open(url, "_blank");
      } else {
        let url = `https://www.google.com/maps/place/${this.propertyToViewFullDetails.Latitude},${this.propertyToViewFullDetails.Longitude}`;
        window.open(url, "_blank");
      }
    },
    executePortalMedia(media) {
      if(media.Filename) {
        // download file
        API.get('agentSignMainRest', `/get?query=/${this.clientName}/download/document/${media.OID}`)
          .then(res => {
            if(res.status === '200') {
              console.log(res)
              const fileURL = window.URL.createObjectURL(new Blob([res.data]))
              const fileLink = document.createElement('a')
              fileLink.href = fileURL
              fileLink.setAttribute('download', `${media.Filename}`)
              document.body.appendChild(fileLink)
              fileLink.click()
            }
          })
      } else {
        window.open(media.Url, "_blank")
      }
    }
  },
  filters: {
    formatDate(val) {
      return moment(val).format('DD/MM/YYYY')
    },
  }
  // beforeDestroy() {
  //   this.$store.commit('RESET_PROPERTY_TO_VIEW_FULL_DETAILS')
  // }
}
</script>

<style scoped>
.footer-z-index {
  z-index: 1000;
}
</style>