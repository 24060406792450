<template>
  <div>
    <v-card 
      flat 
      class="my-12 rounded-sm"
      :loading="loading"
    >
      <v-card-title class="tertiary py-3">
        <span class="text-h5 white--text">Paying rent</span>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-timeline v-if="rentSchedule" dense class="ml-n6 ml-sm-0">
          <v-timeline-item
            :color="rentDue ? 'red' : 'primary'"
            :icon="rentDue ? 'mdi-alert' : 'mdi-check'"   
            small
            fill-dot
          >
            <v-card flat outlined>
              <v-card-text class="">
                <p class="mb-1">{{ rentSchedule.RentAmountDueDisplay }}</p>
                <p class="mb-1">Next payment due: {{ rentSchedule.RentCollectionDate | formatDate }}</p>
                <p v-if="rentSchedule.RentAmountDue" class="mb-0 red--text">Rent due: <span class="font-weight-bold">£{{ rentSchedule.RentAmountDue }}</span></p>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <!-- <v-timeline-item
            :color="openBanking ? 'primary' : 'red'"
            :icon="openBanking ? 'mdi-check' : 'mdi-close'"
            small
            fill-dot
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Open banking
                <v-spacer></v-spacer>
                <v-btn 
                  width="100"
                  @click="openBanking = !openBanking" 
                  :color="openBanking ? 'primary' : 'red'" 
                  depressed
                >
                  <span v-if="openBanking">Active</span>
                  <span v-else class="white--text">Inactive</span>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item> -->
          <v-timeline-item
            color="orange"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                <div class="manual-payment-div"> 
                  <p class="my-0">Manual payment</p>
                  <span class="text-caption grey--text font-italic">This method of payment is only required if you do not have an active standing order or rent arrears.</span>
                </div>
                <v-spacer></v-spacer>
                <v-btn @click="openManualPayment" width="100" color="primary" depressed>Pay</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="700px"
      transition="dialog-transition"
      @click:outside="nullAllViews"
    >
      <PaymentsForm 
        :rentSchedule="rentSchedule"
        v-if="showManualPayment" 
        @closeDialog="dialog = false"
      />
    </v-dialog>
  </div>
</template>

<script>
import PaymentsForm from '@/components/payments/PaymentsForm.vue';
import { mapState } from 'vuex'
import { API } from 'aws-amplify'
import moment from 'moment/src/moment'

export default {
  name: 'tenantJourneyRentCard',
  components: {
    PaymentsForm
  },
  created() {
    if(!this.selectedPersonAccount.RentCollectionDate) this.getRentSchedule()
    else {
      this.rentSchedule = {
        RentAmountDueDisplay: this.selectedPersonAccount.RentAmountDueDisplay,
        RentCollectionDate: this.selectedPersonAccount.RentCollectionDate,
        RentAmountDue: this.selectedPersonAccount.RentAmountDue,
        Balance: this.selectedPersonAccount.Balance
      }
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      userDetails: state => state.userDetails
    }),
    rentDue() {
      if(this.rentSchedule) {
        if(this.rentSchedule.RentAmountDue > 0) return true
      }
      return false
    }
  },
  data() {
    return {
      dialog: false,
      loading: false,
      showManualPayment: false,
      openBanking: true,
      rentSchedule: '',
      snackbar: true
    }
  },
  methods: {
    nullAllViews() {
      this.showManualPayment = false
    },
    openManualPayment() {
      this.showManualPayment = true
      this.dialog = true
    },
    getRentSchedule() {
      this.loading = 'secondary'
      API.post('agentSignMainRest', `/post?query=/${this.selectedPersonAccount.companyShortname}/financials/${this.selectedPersonAccount.personOID}/billing/information`, { body: {}})
        .then(res => {
          this.rentSchedule = res.data
          this.loading = false
        })
    },
  },
  filters: {
    formatDate(val) {
      return moment(val).format('DD/MM/YYYY')
    },
  }
}
</script>

<style scoped>
.manual-payment-div {
  width: 75%;
}
</style>