<template>
  <div>
    <v-card flat class="my-12 rounded-sm">
      <v-card-title class="tertiary py-3">
        <span class="text-h5 white--text">My details</span>
        <v-spacer></v-spacer>
        <!-- <v-btn small text color="white">
          <v-icon left>mdi-pencil</v-icon>
          <span>Edit</span>
        </v-btn> -->
      </v-card-title>
      <v-card-text class="py-3">
        <div class="text-center mt-3">
          <p class="text-h5 text-sm-h4">
            <span class="text-capitalize">{{ selectedPersonAccount.personName }}</span>
          </p>
          <p class="text-body-1 my-0">
            <span>{{ userDetails.email}}</span>
          </p>
          <p class="text-body-1">
            <span class="red--text">{{ selectedPersonAccount.personMobilePhone }}</span>
          </p>

          <!-- <p>{{ selectedPersonAccount }}</p> -->
        </div>
        <!-- <v-btn @click="completeMyDetails" width="100" small depressed class="mt-6" color="primary" :loading="loading">Save</v-btn> -->
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'the-journey-user-details',
  data() {
    return {
      loading: false
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      userDetails: state => state.userDetails
    }),
  },
  methods: {
    completeMyDetails() {
      this.loading = true
      setTimeout( () => {
        this.$emit('completeMyDetails')
        this.loading = false
      }, 1000)
    }
  }
}
</script>

<style>

</style>