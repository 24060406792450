<template>
  <div>
    <v-card :loading="fetchingPropertyDetails" flat class="white my-12 rounded-sm"> 
      <v-card-title class="tertiary white--text py-2">
        <p 
          class="text-h5 my-0 font-weight-medium"
        >
          Property
        </p>
        <v-spacer></v-spacer>
        <v-btn 
          @click="viewPropertyDetails" 
          class="mr-2"
          small 
          outlined
          color="white" 
          fab
        >
          <v-icon size="30">mdi-information-variant</v-icon>
        </v-btn>
      </v-card-title>

      <v-card-text class="pa-5">
        <v-row class="top-row pa-2">
          <v-col v-if="propertyPhotos" class="left-col pa-0" cols="12" sm="4">
            <v-img
              v-if="propertyPhotos.length"
              lazy-src="../../../assets/agreement-summary.jpg"
              :src="propertyPhotos[0].WebAddress"
              contain
              class="rounded-lg"
            ></v-img>
            <v-img 
              v-else
              src="../../../assets/move-in-boxes.jpg"
              gradient="to top right, rgba(100,115,201,.33), rgba(25,32,72,.7)"
            >
              
            </v-img>
          </v-col>
          <v-col v-if="propertyDetails" class="right-col d-flex flex-column py-0" cols="12" sm="8">
            <div class="d-flex align-center">
              <span class="text-h6 secondary--text text--lighten-2">
                {{ propertyDetails.TenancyProperty.AddressNumber }}
                {{ propertyDetails.TenancyProperty.Address1 }}</span>
            </div>
            <div class="d-flex align-center">
              <span class="text-body-1 secondary--text font-weight-light">{{ propertyDetails.Agency }}</span>
            </div>
            <div class="mt-3 mt-sm-auto">
              <span class="text-h3 tertiary--text">
                £{{propertyDetails.RentAdvertised }} 
              </span>
              <span class="text-body-1 tertiary--text font-weight-light">/Month</span>
            </div>
            <!-- {{ propertyDetails }} -->
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { API } from 'aws-amplify'

export default {
  name: 'tenantJourneyPropertycard',
  data() {
    return {
      propertyPhotos: ''
    }
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      propertyDetails: state => state.selectedTenantProperty,
      clientName: state => state.selectedPersonAccount.companyShortname,
      personOID: state => state.selectedPersonAccount.personOID,
    }),
    fetchingPropertyDetails() {
      if(this.propertyDetails) return false
      return 'agentPrimary'
    },
    holdingDeposit() {
      if(this.propertyDetails) return this.propertyDetails.BondRequired / 10
      return 0
    }
  },
  methods: {
    viewPropertyDetails() {
      const photoDetails = { photoDetails: this.propertyPhotos}
      const propertyDetailsWithPhotos = {...this.propertyDetails, ...photoDetails}
      this.$store.commit('SET_PROPERTY_TO_VIEW_FULL_DETAILS', propertyDetailsWithPhotos)
      this.$router.push({ 
        name: 'ApplicantPropertyFullDetails',
        params: {
          agency: this.selectedPersonAccount.companyShortname,
          id: this.propertyDetails.OID
        }
      })
    },
    getPropertyPhotos() {
      API.get('agentSignMainRest', `/get?query=/${this.clientName}/${this.personOID}/tenancies/${this.propertyDetails.OID}/photos`)
      .then(res => {
        this.propertyPhotos = res.data.Data
      })
    }
  },
  watch: {
    propertyDetails(newValue) {
      if(newValue) this.getPropertyPhotos()
    }
  }
}
</script>

<style>

</style>