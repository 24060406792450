import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify';
import MainPagination from './components/reusableComponents/MainPagination.vue'
import PageStepper from './components/reusableComponents/PageStepper.vue'
import LandlordPropertyFloatingButton from '@/components/landlordApp/LandlordPropertyFloatingButton.vue';
import MyChat from '@/components/reusableComponents/MyChat.vue';
import VueSignaturePad from 'vue-signature-pad';
import VCurrencyField from 'v-currency-field'
import { VTextField } from 'vuetify/lib'
import VueFriendlyIframe from 'vue-friendly-iframe';
import Notifications from 'vue-notification'
import velocity from 'velocity-animate' // Used with Notifications

// import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

// Vue.component('l-map', LMap);
// Vue.component('l-tile-layer', LTileLayer);
// Vue.component('l-marker', LMarker);

// AWS AMPLIFY 
import Amplify from '@aws-amplify/core';
import Auth from '@aws-amplify/auth';
import '@aws-amplify/ui-vue';
import awsconfig from './aws-exports';
import './registerServiceWorker'
Amplify.configure(awsconfig);
Auth.configure(awsconfig)

Vue.filter('filterPriceGlobal', function (val) {
  if (!val) return ''
  // let noDecimal = val.toFixed(0)
  const formattedString = val.toLocaleString('en-UK', { style: 'currency', currency: 'GBP' })
  return formattedString.slice(0, -3)
})

// Globally registered components
Vue.component('MainPagination', MainPagination)
Vue.component('PageStepper', PageStepper)
Vue.component('MyChat', MyChat);
Vue.component('LandlordPropertyFloatingButton', LandlordPropertyFloatingButton)
Vue.use(VueSignaturePad);
Vue.component('v-text-field', VTextField)
Vue.use(VCurrencyField, { 
  locale: 'pt-BR',
	decimalLength: 0,
	autoDecimalMode: true,
	min: null,
	max: null,
	defaultValue: 0,
  valueAsInteger: false,
  allowNegative: true
})
Vue.use(VueFriendlyIframe);
Vue.use(Notifications, { velocity })

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
