<template>
  <div>
    <v-card flat>
      <v-card-title class="agentPrimary white--text py-2">
        Payment Checkout
        <v-spacer></v-spacer>
        <v-btn @click="closeDialog" color="white" fab text>
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text class="pt-8 pb-0">
        <v-row v-if="!formConfirmed">
          <v-col cols="12" sm="6">
            <v-text-field
              v-model="inputName"
              outlined
              name="name"
              label="Full name"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              v-model="inputAddress"
              outlined
              name="address"
              label="Property address"
              :rules="rules"
            ></v-text-field>
            <v-text-field
              v-model="inputAmount"
              outlined
              name="amount"
              label="Amount"
              prefix="£"
              :rules="rules"
            ></v-text-field>            
            <v-text-field
              :value="orderID"
              readonly
              filled
              shaped
              name="reference"
              label="Reference"
            ></v-text-field>
          </v-col>
        </v-row>

        <!-- Confirmed details -->

        <div v-if="formConfirmed" class="mt-n3 mb-6">
          <div>
            <p class="mb-0 font-weight-light text-body-1">Name:</p>
            <p class="my-0 text-h6">
              {{ inputName }}
            </p>

            <p class="mb-0 mt-3 font-weight-light text-body-1">Property:</p>
            <p class="my-0 text-h6">
              {{ inputAddress }}
            </p>


            <p class="mb-0 mt-3 font-weight-light text-body-1">Amount:</p>
            <p class="my-0 text-h6">
              £{{ inputAmount }}
            </p>

            <p class="mb-0 mt-3 font-weight-light text-body-1">Order ID:</p>
            <p class="my-0 text-h6">
              {{ orderID }}
            </p>
          </div>
          <p class="mt-3 mb-0 text-caption">Please note that payments will be collected by Letting Management Centre Ltd (AgentOS).</p>
          <p class="my-0 text-caption font-weight-medium primary--text">You will now be redirected to Barclaycard`s ePDQ page.</p>
        </div>
      </v-card-text>

      <v-card-actions class="px-4">
        <div v-if="formConfirmed">
          <form ref="form" method="post" action="https://mdepayments.epdq.co.uk/ncol/test/orderstandard_utf8.asp" id=form1 name=form1>

            <!-- general parameters: see Form parameters -->

            <input type="hidden" name="PSPID" :value="pspID">

            <input type="hidden" name="ORDERID" :value="orderID">

            <input type="hidden" name="AMOUNT" :value="formattedAmount">

            <input type="hidden" name="CURRENCY" :value="currency">

            <input type="hidden" name="LANGUAGE" :value="language">

            <input type="hidden" name="CN" value="">

            <input type="hidden" name="EMAIL" value="">

            <input type="hidden" name="OWNERZIP" value="">

            <input type="hidden" name="OWNERADDRESS" value="">

            <input type="hidden" name="OWNERCTY" value="">

            <input type="hidden" name="OWNERTOWN" value="">

            <input type="hidden" name="OWNERTELNO" value="">



            <!-- check before the payment: see Security: Check before the payment -->

            <input type="hidden" name="SHASIGN" :value="shaSignIn">

            <!-- layout information: see Look and feel of the payment page -->

            <input type="hidden" name="TITLE" value="">

            <input type="hidden" name="BGCOLOR" value="">

            <input type="hidden" name="TXTCOLOR" value="">

            <input type="hidden" name="TBLBGCOLOR" value="">

            <input type="hidden" name="TBLTXTCOLOR" value="">

            <input type="hidden" name="BUTTONBGCOLOR" value="">

            <input type="hidden" name="BUTTONTXTCOLOR" value="">

            <input type="hidden" name="LOGO" value="">

            <input type="hidden" name="FONTTYPE" value="">



            <!-- post payment redirection: see Transaction feedback to the customer -->

            <input type="hidden" name="ACCEPTURL" :value="acceptURL">

            <input type="hidden" name="DECLINEURL" :value="errorURL">

            <input type="hidden" name="EXCEPTIONURL" value="">

            <input type="hidden" name="CANCELURL" :value="paymentsURL">

            <input type="submit" value="checkout" id=submit2 name=submit2>

          </form>
        </div>
        <v-btn 
          v-if="formConfirmed"
          @click="formConfirmed = false"
          class="ml-5"
          color="red" 
          outlined
        >back</v-btn>
        <v-btn 
          @click="confirmDetails"
          v-if="!formConfirmed"
          :disabled="!inputAmount || !inputAddress || !inputName"
          color="primary"
          depressed
          dark
          :loading="loading"
        >
          <span class="">Continue</span>
          <v-icon color="white" right>mdi-arrow-right</v-icon>
        </v-btn>
        <v-spacer></v-spacer>
        <div>
          <v-img
            alt="AgentOS logo"
            contain
            src="../../assets/AgentOS-Logo.png"
            width="120"
          />
        </div>
      </v-card-actions>
    </v-card>
  </div>
</template>

<script>
import { API } from 'aws-amplify' //https://www.npmjs.com/package/js-sha512
import { mapState } from 'vuex'

export default {
  name: 'paymentsForm',
  props: {
    rentSchedule: {
      type: Object
    }
  },
  data() {
    return {
      inputName: '',
      inputAddress: '',
      inputAmount: '',
      encodedText: '',
      rules: [
        value => !!value || `Value required`
      ],
      // Hidden params 
      pspID: 'epdq7019635',
      shaSignIn: '',
      orderID: '',
      currency: 'GBP',
      language: 'en_US',
      //reroute URL params
      paymentsURL: 'https://dev.agentsign.co.uk', // assigned in created() hook
      // acceptURL: 'https://dev.agentsign.co.uk/payments/success',
      acceptURL: 'https://dev.agentsign.co.uk',
      // errorURL: 'https://dev.agentsign.co.uk/payments/error',
      errorURL: 'https://dev.agentsign.co.uk',
      //other
      formConfirmed: false,
      loading: false,
    }
  },
  created() {
    this.inputName = this.selectedPersonAccount.personName
    this.inputAddress = `${this.selectedTenantProperty.TenancyProperty.AddressNumber} ${this.selectedTenantProperty.TenancyProperty.Address1}`
    this.inputAmount = this.rentSchedule.RentAmountDue.toString()
    this.orderID = this.selectedPersonAccount.globalReference
    // this.randomRef()
    // this.paymentsURL = `https://dev.agentsign.co.uk/${this.selectedPersonAccount.companyShortname}/tenant-journey/${this.selectedPersonAccount.personOID}`
  },
  computed: {
    ...mapState({
      selectedPersonAccount: state => state.selectedPersonAccount,
      selectedTenantProperty: state => state.selectedTenantProperty,
      userDetails: state => state.userDetails
    }),
    formattedAmount() {
      if(this.inputAmount) {
        const amountToNumber = parseFloat(this.inputAmount.replace(/,/g, ''))
        return amountToNumber * 100
      }
      return 0
    }
  },
  methods: {
    randomRef() {
      const id = Math.random().toString(36).substr(2, 9);
      this. orderID = id
    },
    confirmDetails() {
      this.loading = true
      const postParams = {
        body: {
          stringValues: {
            acceptURL: this.acceptURL,
            amount: this.formattedAmount,
            cancelURL: this.paymentsURL,
            currency: this.currency,
            declineURL: this.errorURL,
            language: this.language,
            orderID: this.orderID,
            pspID: this.pspID
          }
        },
      }
      API.post('agentSignMainRest', '/hash', postParams)
        .then( res => {
          this.shaSignIn = res.data.hashedString
          this.formConfirmed = true
          this.loading = false
        })
    },
    closeDialog() {
      this.formConfirmed = false
      this.$emit('closeDialog')
    },
    resetFormValues() {
      this.inputName = ''
      this.inputAddress = ''
      this.inputAmount = ''
      this.shaSignIn = ''
    }
  },
  watch: {
    inputAmount(newValue) {
      if(newValue.length > 3) { // added .length to solve error caused by setting rent on created()
        const result = newValue.replace(/\D/g, "")
        .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        this.$nextTick(() => this.inputAmount = result);
      }
    }
  }
}
</script>

<style scoped>
#submit2 {
  background:#59ddb7;
  color: white;
  font-size: 0.875rem;
  text-transform: uppercase;
  font-weight: 500;
  border-radius: 3px;
  height: 36px;
  width: 150px;
  padding: 5px 8px;

}
</style>