import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {},
  state: {
    userDetails: '',
    // Properties
    favouriteProperties: '',
    linkedProperties: '',
    selectedProperty: '', // aka HotSeatProperty at top of the page 
    propertyToViewFullDetails: '', // any property which is selected when user clicks on Property Details (information) button
    propertyMakeOffer: '',
    propertyViewOffer: '',
    selectedTenantProperty: '',
    // Accounts
    personAccounts: '',
    selectedPersonAccount: '',
    selectedAccountAppointments: '',
    // Searches
    searchLoader: false,
    searchPreferences: '',
    advertisedTenancies: '',
    advertisedSales: '',
    applicantSearchPropertyTypes: []
  },
  mutations: {
    SET_USER_DETAILS(state, data) {
      state.userDetails = data
    },
    SET_ALL_PERSON_ACCOUNTS(state, data) {
      state.personAccounts = data
      localStorage.setItem('personAccounts', JSON.stringify(data))
    },
    SET_SELECTED_PERSON_ACCOUNT(state, data) {
      state.selectedPersonAccount = data
      localStorage.setItem('selectedPersonAccount', JSON.stringify(data))
    },
    SET_SELECTED_ACCOUNT_APPOINTMENTS(state, data) {
      state.selectedAccountAppointments = data
    },
    // PROPERTIES ====================================
    SET_LINKED_PROPERTIES(state, data) {
      state.linkedProperties = data
    },
    SET_SELECTED_PROPERTY(state, data) {
      state.selectedProperty = data
      localStorage.setItem('selectedProperty', JSON.stringify(data))
    },
    SET_SELECTED_TENANT_PROPERTY(state, data) {
      state.selectedTenantProperty = data
      localStorage.setItem('selectedTenantProperty', JSON.stringify(data))
    },
    SET_PROPERTY_TO_VIEW_FULL_DETAILS(state, data) {
      state.propertyToViewFullDetails = data
      localStorage.setItem('propertyToViewFullDetails', JSON.stringify(data))
    },
    RESET_PROPERTY_TO_VIEW_FULL_DETAILS(state) {
      state.propertyToViewFullDetails = ''
    },
    SET_FAV_PROPERTIES(state, data) {
      state.favouriteProperties = data
    },
    SET_PROPERTY_MAKE_OFFER(state, data) {
      state.propertyMakeOffer = data
    },
    SET_PROPERTY_VIEW_OFFER(state, data) {
      state.propertyViewOffer = data
    },
    // SEARCHES ==================================
    TOGGLE_SEARCH_LOADER(state, data) {
      state.searchLoader = data
    },
    SET_APPLICANT_SEARCH_PREFERENCES(state, data) {
      state.searchPreferences = data
    },
    SET_TENANCIES_ADVERTISED(state, data) {
      state.advertisedTenancies = data
    },
    SET_SALES_ADVERTISED(state, data) {
      state.advertisedSales = data
    },
    SET_APPLICANT_SEARCH_PROPERTY_TYPES(state, data) {
      state.applicantSearchPropertyTypes = data
    },
    RESET_PROPERTY_VALUES(state) { // being called when a new personAccount is committed
      state.selectedPersonAccount = ''
      state.linkedProperties = ''
      state.selectedProperty = ''
      state.selectedTenantProperty = ''
      state.favouriteProperties = ''
      state.searchPreferences = ''
      state.advertisedTenancies = ''
      state.advertisedSales = ''
      state.selectedAccountAppointments = ''
      state.propertyMakeOffer = ''
      state.propertyViewOffer = ''
    },
    LOGOUT(state) {
      state.userDetails = ''
      state.personAccounts = ''
      state.selectedPersonAccount = ''
      state.linkedProperties = ''
      state.selectedProperty = ''
      state.selectedTenantProperty = ''
      state.favouriteProperties = ''
      state.searchPreferences = ''
      state.advertisedTenancies = ''
      state.advertisedSales = ''
      state.selectedAccountAppointments = ''
      state.propertyMakeOffer = ''
      state.propertyViewOffer = ''
      state.applicantSearchPropertyTypes = []
      localStorage.removeItem('personAccounts')
      localStorage.removeItem('selectedPersonAccount')
      localStorage.removeItem('selectedProperty')
      localStorage.removeItem('selectedTenantProperty')
      localStorage.removeItem('propertyToViewFullDetails')

    },
  },
  actions: {},
  getters: {
    allMyproperties(state) {
      return state.linkedProperties.length + state.favouriteProperties.length
    }
  }
  
})
