<template>
  <div>
    <v-row class="px-5 py-1">
      <v-col cols="12" >
        <div class="mx-3 my-5 pr-0 text-center secondary--text text--lighten-1">
          <p class="mt-3 mb-0 mx-0 text-h4 font-weight-medium">{{ returnCompanyName(shortname) }}</p>
          <p class="mt-0 mb-0 mx-0 text-h6 font-weight-light">{{ sortedAccountsArray(companyAccounts).length }} active accounts</p>
        </div>
        <transition-group
          name="group-slide-left"
          tag="div"
          appear
          class="d-flex flex-wrap justify-center"
        >
          <div
            class="ma-3 account-card"
            cols="12" md="4"
            v-for="(person, index) in sortedAccountsArray(companyAccounts)" 
            :key="person.id"
            :style="`--i: ${index}`"
          >
            <v-card
              class="rounded-sm"
              :class="returnAccountColor(person)"
              @click="navigateTo(person)"
              flat
            >
              <v-icon v-if="person.personType === 'Applicant Buyer'" class="card-icon" size="70" color="white">mdi-handshake</v-icon>
              <v-icon v-if="person.personType === 'Applicant Tenant'" class="card-icon" size="70" color="white">mdi-home-city</v-icon>
              <v-icon v-if="person.personType === 'Tenant'" class="card-icon" size="70" color="white">mdi-account</v-icon>
              <v-card-text class="d-flex pt-3 mt-n2">
                <div>
                  <p v-if="person.personType === 'Applicant Tenant'" class="white--text text-h4 mb-0 ">Rental Account</p>
                  <p v-if="person.personType === 'Applicant Buyer'" class=" white--text text-h4 mb-0 ">Buyer Account</p>
                  <p v-if="person.personType === 'Tenant'" class=" white--text text-h4 mb-0 ">Tenant Account</p>
                  <p class="white--text text-caption font-weight-thin mt-1 mb-10">Created: {{ person.accountCreated | formatDate }}</p>
                </div>
                <v-spacer></v-spacer>
                <v-btn 
                  @click.stop="openAccountDetails(person)" 
                  color="white" 
                  x-small
                  fab
                  outlined
                >
                  <v-icon>mdi-information-variant</v-icon>
                </v-btn>
              </v-card-text>
            </v-card>
          </div>
        </transition-group>
      </v-col>
    </v-row>

    <v-dialog
        v-model="detailsDialog"
        max-width="700px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="py-2 primary white--text">
            <span class="font-weight-light text--white">Account details</span>
            <v-spacer></v-spacer>
            <v-btn small text fab color="white" @click="detailsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-3">
            <div class="name">
              <p class="my-0 font-weight-bold text-h5">{{ selectedAccountDetails.personName}}</p>
              <p class="my-0 text-body-1">{{ selectedAccountDetails.displayInformation }}</p>
            </div>
            <v-divider class="my-3"></v-divider>
              <p class="my-0"><span class="font-weight-bold">Agency:</span> {{ selectedAccountDetails.companyName }}</p>
              <p class="my-0"><span class="font-weight-bold">Accountn type:</span>: {{ selectedAccountDetails.personType }}</p>
              <p class="my-0"><span class="font-weight-bold">Created: </span> {{ selectedAccountDetails.accountCreated | formatDate }}</p>
          </v-card-text>
          <v-card-actions class="pb-4">
            <v-btn @click="hideAccount(selectedAccountDetails.personOID)" color="primary" depressed>
              Hide account
              <v-icon right>mdi-eye-off-outline</v-icon>
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </div>
</template>

<script>
import moment from 'moment/src/moment'


export default {
  name: 'theHomepageTabItem',
  props: {
    personAccounts: {
      Type: Array
    },
    companyAccounts: {
      Type: Array
    },
    shortname: {
      Type: String
    },
    hiddenAccounts: {
      Type: Array
    }
  },
  data() {
    return {
      detailsDialog: false,
      selectedAccountDetails: ''
    }
  },
  methods: {
    returnCompanyName(name) {
      const company = this.personAccounts.find(obj => obj.companyShortname === name)
      return company.companyName
    },
    sortedAccountsArray(array) { // sorted by created date && only Applicant Tenant + Applicant Buyer accounts + notHidden
      const sortedByDate = array.slice().sort((a,b) => a.accountCreated < b.accountCreated ? 1 : -1)
      const visbleAccountsOnly = sortedByDate.filter( account => {
        if(account.personType === 'Applicant Tenant' || account.personType === 'Applicant Buyer' || account.personType === 'Tenant') return account
      })
      const notHiddenAccounts = visbleAccountsOnly.filter( account => {
        const isHidden =  this.hiddenAccounts.some( hiddenAccount => hiddenAccount.personOID === account.personOID)
        if(!isHidden) return account
      })
      return notHiddenAccounts
    },
    returnAccountColor(person) {
      // if(person.personType == 'Tenant') return 'tertiaryDark'
      if(person.personType == 'Applicant Tenant') return 'tertiaryDark'
      if(person.personType == 'Tenant') return 'tertiary'
      if(person.personType == 'Applicant Buyer') return 'secondary'
      return 'grey'
    },
    navigateTo(person) {
      this.$store.commit('RESET_PROPERTY_VALUES')
      this.setSelectedPersonAccount(person)
      if(person.personType === 'Applicant Buyer' || person.personType === 'Applicant Tenant') {
        this.$router.push({
          name: 'ApplicantProperties',
          params: {
            agency: person.companyShortname
          }
        })
      }
      if(person.personType === 'Tenant') {
        this.$router.push({
          name: `TenantJourney`,
          params: {
            agency: person.companyShortname,
              id: person.personOID
          }
        })
      }
    },
    setSelectedPersonAccount(person) {
      this.$store.commit('SET_SELECTED_PERSON_ACCOUNT', person)
    },
    openAccountDetails(person) {
      this.selectedAccountDetails = person
      this.detailsDialog = true 
    },
    hideAccount(id) {
      this.$emit('hideAccount', id)
      this.detailsDialog = false
    }
  },
  filters: {
    formatDate(val) {
      return moment(val).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>
.account-card {
  flex: 1 1 300px;
  max-width: 425px;
}
.card-icon {
  position: absolute;
  bottom: 0;
  right: 10px;
  color: white;
  opacity: .5;
}
</style>