/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAgentSignPersonAccount = /* GraphQL */ `
  query GetAgentSignPersonAccount($id: ID!) {
    getAgentSignPersonAccount(id: $id) {
      id
      companyName
      accountCreated
      displayInformation
      personName
      personMobilePhone
      personLandline
      personOID
      companyShortname
      personType
      globalReference
      isArchived
      isAnonymised
      tenancyClosed
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAgentSignPersonAccounts = /* GraphQL */ `
  query ListAgentSignPersonAccounts(
    $filter: ModelAgentSignPersonAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentSignPersonAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        companyName
        accountCreated
        displayInformation
        personName
        personMobilePhone
        personLandline
        personOID
        companyShortname
        personType
        globalReference
        isArchived
        isAnonymised
        tenancyClosed
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAgentSignPersonAccountSyncDateTime = /* GraphQL */ `
  query GetAgentSignPersonAccountSyncDateTime($id: ID!) {
    getAgentSignPersonAccountSyncDateTime(id: $id) {
      id
      lastUpdate
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAgentSignPersonAccountSyncDateTimes = /* GraphQL */ `
  query ListAgentSignPersonAccountSyncDateTimes(
    $filter: ModelAgentSignPersonAccountSyncDateTimeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentSignPersonAccountSyncDateTimes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        lastUpdate
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAgentSignFavouriteProperty = /* GraphQL */ `
  query GetAgentSignFavouriteProperty($id: ID!) {
    getAgentSignFavouriteProperty(id: $id) {
      id
      propertyOID
      personAccountID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAgentSignFavouritePropertys = /* GraphQL */ `
  query ListAgentSignFavouritePropertys(
    $filter: ModelAgentSignFavouritePropertyFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentSignFavouritePropertys(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        propertyOID
        personAccountID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
export const getAgentSignHiddenPersonAccount = /* GraphQL */ `
  query GetAgentSignHiddenPersonAccount($id: ID!) {
    getAgentSignHiddenPersonAccount(id: $id) {
      id
      personOID
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listAgentSignHiddenPersonAccounts = /* GraphQL */ `
  query ListAgentSignHiddenPersonAccounts(
    $filter: ModelAgentSignHiddenPersonAccountFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAgentSignHiddenPersonAccounts(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        personOID
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
