<template>
  <div>
    <v-card flat class="my-12 rounded-sm">
      <v-card-title class="tertiary py-3">
        <span class="text-h5 white--text">Offer</span>
      </v-card-title>
      <v-card-text class="pt-3">
        <v-timeline dense class="ml-n6 ml-sm-0">
          <v-timeline-item
            color="primary"
            small
            fill-dot
            icon="mdi-check"
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                <span>Offer accepted</span>
              </v-card-text>
            </v-card>
          </v-timeline-item>
          <!-- <v-timeline-item
            :color="feePayed ? 'primary' : 'orange'"
            small
            :fill-dot="feePayed"
            :icon="feePayed ? 'mdi-check' : ''"
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Holding fee
                <v-spacer></v-spacer>
                <v-btn 
                  @click="payHoldingFee" 
                  color="primary" 
                  disabled
                  width="100" 
                  depressed
                >
                  <span class="" v-if="!feePayed">Pay</span>
                  <span class="" v-else>Paid</span>
                </v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item> -->
          <v-timeline-item
            v-if="feePayed"
            color="red lighten-3"
            small
          >
            <v-card flat outlined>
              <v-card-text class="d-flex align-center">
                Application
                <v-spacer></v-spacer>
                <v-btn color="primary" width="100" depressed>Start</v-btn>
              </v-card-text>
            </v-card>
          </v-timeline-item>
        </v-timeline>
        <!-- <v-btn @click="offerComplete" width="100" depressed small class="mt-6" color="primary">Save</v-btn> -->
      </v-card-text>
    </v-card>

    <v-dialog
      v-model="dialog"
      scrollable
      max-width="700px"
      transition="dialog-transition"
      @click:outside="nullAllViews"
    >
      <PayHoldingFeeCard v-if="showPayHolding" @holdingFeePaid="feePayed = true" />
    </v-dialog>
  </div>
</template>

<script>
import PayHoldingFeeCard from '@/components/tenantApp/offer/PayHoldingFeeCard.vue';
export default {
  name: 'the-journey-offer-card',
  components: {
    PayHoldingFeeCard
  },
  data() {
    return {
      dialog: false,
      feePayed: false,
      showPayHolding: false,
    }
  },
  methods: {
    offerComplete() {
      this.$emit('offerComplete')
    },
    nullAllViews() {
      this.showPayHolding = false
    },
    payHoldingFee() {
      this.showPayHolding = true,
      this.dialog = true
    }
  }
}
</script>

<style>

</style>