<template>
  <div>
    <v-container fluid>
      <div class="d-flex flex-column justify-center align-center">

        <div v-if="!personAccounts" class="text-center">
          <LoadingBounce />
          <p class="mt-5 mb-1 text-h5">Syncing your account.</p>
          <p class="my-0 font-weight-light text-body-1">This will only take a few seconds...</p>
          <p class="mt-0 font-weight-light text-caption">Try refreshing if it takes too long!</p>
        </div>

      </div>

      <div v-if="newUserNoAccounts" class="mx-12 text-center">
        <p class="text-h4 mt-8">Hi there, you don`t seem to have any saved accounts.</p>
        <p class="text-h4 mt-8"> Please contact your agent for more information about setting up an account.</p>
        <div class="d-flex justify-center">
          <v-img
            alt="Application logo"
            class="cursor-pointer"
            contain
            src="../assets/AgentOS-Logo.png"
            height="120"
            @click="$router.push('/')"
          />
        </div>
      </div>

      <v-row>
        <v-col cols="12" sm="12" md="10" offset-md="1" lg="8" offset-lg="2">
          <div v-if="personAccounts.length">
            <v-tabs
              v-model="tab"
              class="mb-2 rounded-sm"
              background-color="white"
              show-arrows
              active-class="tertiaryDark white--text"
            >
              <v-tabs-slider color="white"></v-tabs-slider>
              <v-tab
                v-for="(array, shortname, index) in accountsSortedByShortname" 
                :key="index"
              >
                <span 
                  class="d-inline-block text-truncate custom-transform-class text-none"
                  style="max-width: 200px;"
                >{{ returnCompanyName(shortname) }}</span>
              </v-tab>
            </v-tabs>

            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="(array, shortname, index) in accountsSortedByShortname" 
                :key="index"
              >
                <div class="d-flex justify-end pt-3 pr-8 pb-0 mb-n6">
                  <v-btn 
                    @click="hiddenAccountsDialog = true" 
                    class="mr-3"
                    color="tertiaryDark" 
                    small 
                    fab
                    outlined
                  >
                    <v-icon>mdi-eye-off-outline</v-icon>
                  </v-btn>
                  <v-btn 
                    color="tertiaryDark" 
                    small 
                    outlined
                    fab
                    @click="syncPersonAccounts(false)"
                  >
                    <v-icon>mdi-refresh</v-icon>
                  </v-btn>
                </div>
                
                <TheHomepageTabItem 
                  :companyAccounts="array"
                  :shortname="shortname"
                  :index="index"
                  :personAccounts="personAccounts"
                  :hiddenAccounts="hiddenAccounts"
                  @hideAccount="hideAccount"
                />
              </v-tab-item>
            </v-tabs-items>
          </div>
        </v-col>
      </v-row>

      <v-dialog
        v-model="hiddenAccountsDialog"
        max-width="400px"
        transition="dialog-transition"
      >
        <v-card>
          <v-card-title class="primary py-2">
            <span class="font-weight-light white--text">Hidden accounts</span>
            <v-spacer></v-spacer>
            <v-btn small text fab color="white" @click="hiddenAccountsDialog = false">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-card-title>
          <v-card-text class="pt-3">
            <v-card 
              v-for="account in hiddenAccountsFullDetails" 
              :key="account.personOID"
              outlined
              flat
              class="d-md-flex flex-wrap justify-space-between align-start my-3"
            >
              <v-card-title class="d-block">
                <div>
                  <p v-if="account.personType === 'Applicant Tenant'" class="text-h6 my-0 font-weight-medium">Rental Account</p>
                  <p v-else-if="account.personType === 'Applicant Buyer'" class=" text-h6 my-0 font-weight-medium">Buyer Account</p>
                  <p v-else class="text-h6 my-0 font-weight-medium">{{ account.personType }}</p>
                </div>
                <p class="my-0 text-caption">{{ account.companyName }}</p>
              </v-card-title>
              <v-card-text>
                <p class="my-0">Created: {{ account.accountCreated | formatDate }}</p>
              </v-card-text>
              <v-card-actions>
                <v-btn @click="showAccount(account.personOID)" color="primary" depressed>
                  <v-icon left>mdi-eye-outline</v-icon>
                  Show account
                </v-btn>
              </v-card-actions>
            </v-card>
            <span v-if="!hiddenAccountsFullDetails.length" class="text-body-1">No hidden accounts</span>
          </v-card-text>
        </v-card>
      </v-dialog>

      <!-- <v-snackbar
        v-model="rentNotificationSnackbar"
        top
        right
        transition="scroll-y-transition"
        color="agentPrimary"
      >
        <span class="text-body-1">My rent is due: £{{ }}</span>

        <template v-slot:action="{ attrs }">
          <v-btn
            color="white"
            text
            v-bind="attrs"
            @click="rentNotificationSnackbar = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar> -->

    </v-container>
  </div>
</template>

<script>
import TheHomepageTabItem from '@/components/TheHomepageTabItem.vue';
import moment from 'moment/src/moment'
import { API, graphqlOperation  } from 'aws-amplify'
import { createAgentSignPersonAccount } from '../graphql/mutations'
import { deleteAgentSignPersonAccount } from '../graphql/mutations'
import { listAgentSignPersonAccounts } from '../graphql/queries'
import { listAgentSignPersonAccountSyncDateTimes } from '../graphql/queries'
import { createAgentSignPersonAccountSyncDateTime } from '../graphql/mutations'
import { updateAgentSignPersonAccountSyncDateTime } from '../graphql/mutations'
import { listAgentSignHiddenPersonAccounts } from '../graphql/queries'
import { createAgentSignHiddenPersonAccount } from '../graphql/mutations'
import { deleteAgentSignHiddenPersonAccount } from '../graphql/mutations'
import LoadingBounce from '@/components/reusableComponents/LoadingBounce.vue';

export default {
  name: 'homepage',
  components: {
    LoadingBounce,
    TheHomepageTabItem
  },
  created() {
    API.get('agentSignMainRest', '/test')
    .then(res => console.log('/test api: ', res))

    this.checkIfAccountSyncNeeded(),
    this.getHiddenAccounts()


  },
  data() {
    return {
      personAccounts: '',
      hiddenAccounts: '',
      hiddenAccountsDialog: false,
      tab: null,
      detailsDialog: false,
      selectedAccountDetails: '',
      newUserNoAccounts: false,
      rentNotificationSnackbar: false
    }
  },
  methods: {
    getHiddenAccounts() {
      API.graphql(graphqlOperation(listAgentSignHiddenPersonAccounts))
        .then( res => {
          this.hiddenAccounts = res.data.listAgentSignHiddenPersonAccounts.items
        })
    },
    hideAccount(personOID) {
      const payload = {
        personOID: personOID
      }
      API.graphql(graphqlOperation(createAgentSignHiddenPersonAccount, {input: payload}))
        .then(() => {
          this.detailsDialog = false
          this.getHiddenAccounts()
        })
    },
    showAccount(personOID) { //unhide account
      //Get amplify ID for this account
      const amplifyObject = this.hiddenAccounts.find(account => account.personOID === personOID)
      const payload = {
        id: amplifyObject.id
      }
      //delete amplify obj
      API.graphql(graphqlOperation(deleteAgentSignHiddenPersonAccount, {input: payload}))
        .then( () => {
          this.getHiddenAccounts()
          this.hiddenAccountsDialog = false
        })
    },
    checkIfAccountSyncNeeded() { // Have 7 days have passed OR are URL query present
      console.log('Checking if account sync needed...')

      API.graphql(graphqlOperation(listAgentSignPersonAccountSyncDateTimes)) // call DB to check if any DateTime stored for last sync...
       .then( res => {
         if(res.data.listAgentSignPersonAccountSyncDateTimes.items.length) { // dateTime exists = NOT first Login
           
           const lastestSyncDateTime = Date.parse(res.data.listAgentSignPersonAccountSyncDateTimes.items[0].lastUpdate)
           const dateTimeNow = Date.now()
           const oneWeek = 1000 * 60 * 60 * 24 * 7
           let timeElapsed =  dateTimeNow - lastestSyncDateTime

           if(timeElapsed > oneWeek || this.$route.query.agency && !this.$route.query.rentDue) { // a week has passed OR query in URL
             this.syncPersonAccounts(false) // firstTime login false
           } else  { // a week has not passed yet && NO url query
             console.log('No sync needed, fetching saved accounts.')
             this.getSavedAccounts()
           }
         } 
         else { // no dateTime saved - First login
           console.log('First time login!')
           this.syncPersonAccounts(true) // firstTime login true
         }
       }) 
    },
    syncPersonAccounts(firstTimeLogin) { // request personAccounts (via Lambda) to AgentOS endpoint
      console.log('Requesting accounts sync from AgentOS...')

      this.personAccounts = ''
      API.get('agentSignMainRest', '/people')
        .then( res => {
          if(res.status === '200') { // response from lambda ok
            if(res.people.Data.length) { // user has personAccounts with AgentOS
              this.saveAccountSynchDateTime() // save timeDate of this account sync

              const apiReturnedAccountsArray = res.people.Data
              
              if(firstTimeLogin) { // save synced accounts to Dynamo DB
                let requests = apiReturnedAccountsArray.map(person => this.savePersonAccount(person)) 
                Promise.all(requests)
                  .then( () => {
                    this.getSavedAccounts() // accounts are saved in DB, now call Dynamo DB to render data
                  })
              } else { // not firstTime login
                this.updateSavedAccount(apiReturnedAccountsArray)
              }
            } else { // user has no personAccounts with AgentOS
              this.personAccounts = []
              this.newUserNoAccounts = true // User informend about lack of accounts with rendered msg 
            }
          } else {
            console.log('Error: ', res)
          }
        })
    },
    updateSavedAccount(latestAccounts) { // This method might very well be buggy...
      API.graphql(graphqlOperation(listAgentSignPersonAccounts, {limit: 100})) // get person accounts array already saved in DB
        .then( res => {
          const savedAccounts = res.data.listAgentSignPersonAccounts.items // existing accounts in DB

          // Remove accounts that are no longer relevant
          savedAccounts.forEach(savedAccount => { // compare each account by id
            let accountActive = latestAccounts.some(latestAccount => savedAccount.personOID === latestAccount.PersonOID) // notice caps difference in personOID & PersonOID
            if(!accountActive) {  // savedAccount not present among latestAccounts
              const personAccount = {
                id: savedAccount.id
              }
              return API.graphql(graphqlOperation(deleteAgentSignPersonAccount, {input: personAccount})) // delete savedAccount
                .then(res => {
                  console.log('Following account deleted: ', res)
                })
                .catch(err => console.log('Error deleting inactive savedAccount!', err))
            } else {
              console.log('Account still active: ', savedAccount)
            }
          })

          // Then do the reverse - save new accounts that were not previoucly present in the DB
          latestAccounts.forEach(latestAccount => {
            let accountInDataBase = savedAccounts.some(savedAccount => latestAccount.PersonOID === savedAccount.personOID) // notice caps difference in personOID & PersonOID

            if(!accountInDataBase) { // save the account in the DB
              this.savePersonAccount(latestAccount)
            } else {
              console.log('Account already exists in DB!')
            }
          })
          this.getSavedAccounts() 
        })
    },
    checkUrlParams() {  // baserURL ?agency=letmcletting&rentDue=true
      if(this.$route.query.agency && this.$route.query.rentDue === 'true') { // Agency name and rentDue queiries detected
        console.log('Rent due based on url query!')
        this.rerouteToTenantRentDue()
      }
      else if(this.$route.query.agency && !this.$route.query.rentDue) { // Agency name query detected
        console.log('Only refresh based on url query!')
        const availableShortnames = Object.keys(this.accountsSortedByShortname)
        const shortnameIndex = availableShortnames.indexOf(this.$route.query.agency)
        this.tab = shortnameIndex
      }
      else {
        console.log('No URL queries!!!')
        this.checkTenantsForRentDue()
      } 
    },
    rerouteToTenantRentDue() { // called if specific url params detected
      const shortname = this.$route.query.agency
      const agencyAccountsArray = this.accountsSortedByShortname[shortname]
      const tenantAccounts = agencyAccountsArray.filter(account => account.personType === 'Tenant')

      const accountsWithBillingInfo = []
      let requests = tenantAccounts.map(account => {    
        return API.post('agentSignMainRest', `/post?query=/${account.companyShortname}/financials/${account.personOID}/billing/information`, { body: {}})
          .then(res => {
            accountsWithBillingInfo.push({...account, ...res.data})
          })
      })
      Promise.all(requests)
        .then( () => {
          console.log('accountBillingInfo', accountsWithBillingInfo)
          const accountWithRentDue = accountsWithBillingInfo.filter( account => account.RentAmountDue > 0) // find 1st account with due rent
          console.log('1st account with rent due: ', accountWithRentDue[0])
          this.$store.commit('SET_SELECTED_PERSON_ACCOUNT', accountWithRentDue[0])
          this.$router.push({
            name: `TenantJourney`,
            params: {
              agency: accountWithRentDue.companyShortname,
              id: accountWithRentDue.personOID
            }
          })
        })
    },
    checkTenantsForRentDue() { // called if no url params - checks all tenant accounts for rentDue
      const personAccounts = this.personAccounts
      const tenantAccounts = personAccounts.filter( account => {
        return account.personType === 'Tenant'
      })

      const accountsWithBillingInfo = []
      let requests = tenantAccounts.map(account => {
        return API.post('agentSignMainRest', `/post?query=/${account.companyShortname}/financials/${account.personOID}/billing/information`, { body: {}})
          .then(res => {
            console.log('billing res: ', res)
            accountsWithBillingInfo.push({...account, ...res.data})
          })
      })
      Promise.all(requests)
        .then( () => {
          console.log('accountBillingInfo', accountsWithBillingInfo)
          const accountsWithRentDue = accountsWithBillingInfo.filter( account => account.RentAmountDue > 0)
          console.log(accountsWithRentDue)
          accountsWithRentDue.forEach( account => {
            setTimeout(() => {
              this.$notify({
                group: 'rentNotifications',
                title: 'Rent overdue',
                text: `Amount: £${account.RentAmountDue}`,
                data: {
                  account: account
                }
                // type: 'success',
              });
            },500)
          })

          // this.$store.commit('SET_SELECTED_PERSON_ACCOUNT', accountsWithRentDue)
          // this.$router.push({
          //   name: `TenantJourney`,
          //   params: {
          //     agency: `${accountsWithRentDue.companyShortname}`
          //   }
          // })
        })






    },
    getSavedAccounts() { // returns personAccounts saved in Dynamo DB
      console.log('Getting saved accounts...')
      API.graphql(graphqlOperation(listAgentSignPersonAccounts, {limit: 1000}))
        .then(res => {
          if(res.data.listAgentSignPersonAccounts.items.length) { // accounts present in DB
            const personAccounts = res.data.listAgentSignPersonAccounts.items
            this.personAccounts = personAccounts
            this.$store.commit('SET_ALL_PERSON_ACCOUNTS', personAccounts)
            this.checkUrlParams()
            } else { // No saved accounts so call API to sync
              this.syncPersonAccounts()
            }
        })
        .catch(err => console.log(err))
    },
    savePersonAccount(person) {  // save account to amplify DB
      const personAccount = {
        companyName: person.CompanyName,
        accountCreated: `${person.CreatedDate}Z`,
        displayInformation: person.Information,
        personName: person.PersonName,
        personOID: person.PersonOID,
        companyShortname: person.CompanyShortName,
        personType: person.PersonType,
        personMobilePhone: person.MobilePhone ? person.Mobilephone : '',
        personLandline: person.LandPhone ? person.LandPhone : '',
        globalReference: person.GlobalReference ? person.GlobalReference : '',
        isArchived: person.IsArchived ? person.IsArchived : false,
        isAnonymised: person.IsAnonymised ? person.IsAnonymised : false,
        tenancyClosed: person.TenancyClosed ? person.TenancyClosed : false,
      }
      return API.graphql(graphqlOperation(createAgentSignPersonAccount, {input: personAccount}))
        .then( () => console.log('PersonAccount saved.'))
        .catch( (err) => console.log('Error in savePersonAccount method.', err))
    },
    saveAccountSynchDateTime() { // saving dateTime of last requested sync from AgentOS api
      const todaysDate = new Date()
      const todaysDateISO = todaysDate.toISOString()
      
      API.graphql(graphqlOperation(listAgentSignPersonAccountSyncDateTimes)) // check if DB table has any entries
        .then(res => {
          if(res.data.listAgentSignPersonAccountSyncDateTimes.items.length) { // if one exists, update it...
            const savedDateTime = res.data.listAgentSignPersonAccountSyncDateTimes.items[0] // 1st and only object in array
            const payload = {
              id: savedDateTime.id,
              lastUpdate: todaysDateISO
            }
            API.graphql(graphqlOperation(updateAgentSignPersonAccountSyncDateTime, {input: payload})) // Update object
              .then( res => {
                console.log('appSync DateTime updated successfully: ', res)
              })
              .catch(err => console.log('appSync DateTime update ERR: ', err))
          } else { // create a first dateTime object
            const payload = {
              lastUpdate: todaysDateISO
            }
            API.graphql(graphqlOperation(createAgentSignPersonAccountSyncDateTime, {input: payload}))
              .then( () => {
                console.log('SyncDateTime created!')
              })
              .catch(err => console.log(err))
          }
        })
    },
    returnCompanyName(name) {
      const company = this.personAccounts.find(obj => obj.companyShortname === name)
      return company.companyName
    },
  },
  computed: {
    accountsSortedByShortname() {
      let personAccounts = this.personAccounts
      const reducedArray = personAccounts.reduce((acc, obj) => {
        const key = obj.companyShortname
        if(!acc[key]) {
          acc[key] = []
        }
        acc[key].push(obj)
        return acc
      }, {})
      // const objectKeys = Object.keys(reducedArray)
        // 
      return reducedArray
    },
    hiddenAccountsFullDetails() {
      if(this.personAccounts) {
        const fullDetails = this.personAccounts.filter( account => {
          const isHidden = this.hiddenAccounts.some(hiddenAccount => hiddenAccount.personOID === account.personOID)
          if(isHidden) return account
        })
        return fullDetails
      } return []
    }
  },
  filters: {
    formatDate(val) {
      return moment(val).format('DD/MM/YYYY')
    }
  }
}
</script>

<style scoped>

/* .secondary-gradient {
  background: linear-gradient(90deg, #262637 20%, #ffffff);
} */

/* .tertiaryDark-gradient {
  background: linear-gradient(90deg, #55B6B4 20%, #ffffff);
} */

</style>


// Some notes

// GET requests passing a string query parameter (See link for alternative ways)
// https://docs.amplify.aws/lib/restapi/fetch/q/platform/js#get-requests-with-query-parameters

// EXAMPLES...
// API.get('agentSignMainRest', '/todos?query=https://api.mocki.io/v1/13f44462')
//   .then( res => {
  //     console.log('Todos obj: ',  res)
//   })

// const postParams = {
//   body: {
  //     name: "Tom Jones",
//     movies: ["I Love You Man", "Role Models"]
//   },
// };

// API.post('agentSignMainRest', '/post', postParams)
//   .then( res => {
  //     console.log('Post obj res: ', res)
//   })

    
